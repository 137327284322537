import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { bodyPadding } from '../../theme/theme';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { getLocalizedUri } from '../../utils/getLocalizedLink';
import { PageContext } from '../../utils/PageProps';
import {
  OpportunitiesQuery,
  Sections_SanityOpportunitiesListSection,
} from '../../__generated__/graphql';
import { ArticleTeaser } from '../ArticleTeaser';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { LineHeading } from '../common/LineHeading';
import { useLocale } from '../CurrentLocale';

export type OpportunitiesListSectionProps = {
  section: Sections_SanityOpportunitiesListSection;
};

export const OpportunitiesListSection: React.FC<OpportunitiesListSectionProps> = ({ section }) => {
  const locale = useLocale();
  const data = useStaticQuery<OpportunitiesQuery>(query);
  const heading = extractWithLocale(section.heading, locale);

  return (
    <>
      <Box as="section" gridColumn="2 / -2" px={bodyPadding}>
        <LineHeading>{heading}</LineHeading>
        <InnerGrid columns={[1, 3]} gridGap={3}>
          {data.jobs.nodes.map((job, i) => {
            const index = job.links?.careersite_job_url?.lastIndexOf('/') ?? 0;
            const link = job.links?.careersite_job_url?.slice(index + 1) ?? '';

            const city = job.locations?.[0]?.city ?? '';

            const jobLink = getJobLink(link, locale);

            return (
              <ArticleTeaser
                key={job.id}
                title={job.title ?? ''}
                kicker={city}
                link={jobLink}
                index={i}
                gridColumn="auto"
                mb={0}
                forceSquare={true}
                animateInColumns={true}
                bg="rgba(213,213,213, 0.5)"
                imageAltText=""
              />
            );
          })}
        </InnerGrid>
      </Box>
    </>
  );
};

const getJobLink = (link: string, locale: PageContext['locale']) => {
  switch (locale) {
    case 'sv':
      return getLocalizedUri(`jobb/${link}`, locale);
    case 'en':
      return getLocalizedUri(`jobs/${link}`, locale);
  }

  return '';
};

const query = graphql`
  query OpportunitiesQuery {
    jobs: allTeamtailorJob {
      nodes {
        id
        title
        locations {
          city
        }
        links {
          careersite_job_url
        }
        department {
          name
        }
      }
    }
  }
`;
