import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { bodyPadding, grid } from '../../theme/theme';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { Sections_SanityTalksListSection } from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { Grid } from '../common/Grid';
import { H2 } from '../common/Heading';
import { InnerGrid } from '../common/InnerGrid';
import { MiniBlockContentRenderer } from '../common/MiniBlockContentRenderer';
import { Text } from '../common/Text';
import { useLocale } from '../CurrentLocale';
import arrow from '../../images/icons/play.svg';
import { NetlifyForm } from '../NetlifyForm';
import { graphql, useStaticQuery } from 'gatsby';
import { TalkTeaser } from '../TalkTeaser';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useIsMobile } from '../../hooks/useIsMobile';

type TalksListSectionProps = {
  section: Sections_SanityTalksListSection;
};

export const TalksListSection: React.FC<TalksListSectionProps> = ({ section }) => {
  const locale = useLocale();
  const { width } = useWindowSize();

  const showMobileView = width <= 831;
  const gridColumnView = showMobileView ? '1 / -1' : '1 / -2';

  const listTitle = extractWithLocale(section.listTitle, locale);

  return (
    <Box as="section" gridColumn="2 / -2" my={3} mb={[4, 6]}>
      <Grid gridTemplateColumns={grid}>
        <InnerGrid px={bodyPadding}>
          <Box gridColumn={gridColumnView} pr={[0, 0, 3]}>
            <GatsbyImage image={section.image?.asset?.gatsbyImageData as any} />

            {showMobileView && <TalksListForm section={section} mobileView={showMobileView} />}

            <H2 pt={4} pb={2} fontSize={[4, 50]}>
              {listTitle}
            </H2>

            <InnerGrid columns={[1, 2, 3]} gridGap={3}>
              <TalksList />
            </InnerGrid>
          </Box>

          {!showMobileView && <TalksListForm section={section} mobileView={showMobileView} />}
        </InnerGrid>
      </Grid>
    </Box>
  );
};

const TalksList: React.FC<any> = () => {
  const locale = useLocale();

  const data = useStaticQuery(query);
  const localeData = extractWithLocale(data, locale);

  if (!localeData) {
    return <></>;
  }

  return (
    <>
      {localeData.edges.map((item, index) => {
        const title = extractWithLocale(item.node.heading, locale);
        const preamble = extractWithLocale(item.node.teaser?.teaserPreamble, locale);
        const link = extractWithLocale(item.node.slug, locale);

        return (
          <TalkTeaser
            title={title || ''}
            preamble={preamble || ''}
            kicker={'Talks'}
            link={'/stuff/' + link?.current || ''}
            fluidImage={item.node.image?.asset?.gatsbyImageData}
            isFeatured={false}
            index={index}
            eventDate={null}
            imageAltText={title || ''}
          />
        );
      })}
    </>
  );
};

type TalksListFormSectionProps = {
  section: Sections_SanityTalksListSection;
  mobileView: Boolean;
};

const TalksListForm: React.FC<TalksListFormSectionProps> = ({ section, mobileView }) => {
  const locale = useLocale();
  const isMobile = useIsMobile();

  const formHeading = extractWithLocale(section.formHeading, locale);
  const formContent = extractWithLocale(section.formContent, locale);
  const formEmailPlaceholder = extractWithLocale(section.formEmailPlaceholder, locale);
  const gdprDescription = extractWithLocale(section.gdprDescription, locale);
  const submitForm = extractWithLocale(section.formSubmitLabel, locale);

  const stickyStyle = isMobile ? { position: 'sticky', bottom: 0, backgroundColor: '#fff' } : {};

  return (
    <Box gridColumn="-2 / -1">
      <Box style={{ position: 'sticky', top: '6rem', width: '100%' }} mt={[20, 20, 0]}>
        <hr style={{ borderTop: '1px solid black', borderBottom: 0, paddingTop: 0, margin: 0 }} />

        {!mobileView && (
          <Text
            fontSize={[3, 26]}
            mt={[20]}
            ml={'2px'}
            fontFamily="Handsome"
            marginTop={15}
            style={{ display: 'inline-flex' }}
          >
            Talks
          </Text>
        )}

        <H2 fontSize={[24, 32]} lineHeight={1.1} mt={[20, 20, 0]} mb={20}>
          {formHeading}
        </H2>
        <hr
          style={{
            borderTop: '1px solid black',
            borderBottom: 0,
            paddingTop: 0,
            margin: 0,
            marginBottom: 20,
          }}
        />

        <StyledFormContent>
          <MiniBlockContentRenderer body={formContent} />
        </StyledFormContent>

        <Box style={stickyStyle} paddingTop={2}>
          <NetlifyForm
            formName={'Talks-submitted-form'}
            hasEmail={true}
            emailPlaceholder={formEmailPlaceholder}
            gdprDescription={gdprDescription}
            sendButtonText={submitForm}
          />
        </Box>
      </Box>
    </Box>
  );
};

const StyledFormContent = styled.div`
  & ul {
    padding-left: 0;
    margin: 0;
    margin-top: 0.5rem;
  }

  & ul li {
    min-height: 24px;
    list-style: none;
    line-height: normal;
    margin-bottom: 15px;
  }

  & ul li:last-child {
    margin-bottom: 20px;
  }

  & ul li::before {
    content: '';
    height: 20px;
    width: 16px;
    float: left;
    background-size: 19px 16px;
    background-image: url('${arrow}');
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 0.5rem;
  }

  & ul li div {
    overflow: auto;
  }
`;

const query = graphql`
  query TalksListQuery {
    en: allSanityArticle(
      limit: 6
      filter: {
        isVisibleOnList: { eq: true }
        category: { title: { eq: "Talks" } }
        slug: { en: { current: { ne: null } } }
      }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          isVisibleOnList
          name {
            en
          }
          slug {
            en {
              current
            }
          }
          teaser {
            teaserPreamble {
              en
            }
          }
          heading {
            en
          }
          image {
            asset {
              gatsbyImageData(width: 600, height: 400)
            }
          }
        }
      }
    }

    sv: allSanityArticle(
      limit: 6
      filter: {
        isVisibleOnList: { eq: true }
        category: { title: { eq: "Talks" } }
        slug: { sv: { current: { ne: null } } }
      }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          isVisibleOnList
          name {
            sv
          }
          slug {
            sv {
              current
            }
          }
          teaser {
            teaserPreamble {
              sv
            }
          }
          heading {
            sv
          }
          image {
            asset {
              gatsbyImageData(width: 600, height: 400)
            }
          }
        }
      }
    }
  }
`;
