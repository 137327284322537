import React, { useContext } from 'react';
import { Sections_SanityInfoSnippetSection_items_SanityInfoSnippet_contact } from '../../__generated__/graphql';
import { Text } from './Text';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { useLocale } from '../CurrentLocale';
import { MiniBlockContentRenderer } from './MiniBlockContentRenderer';
import Obfuscate from "react-obfuscate";
import { ThemeContext } from 'styled-components';

export type EmployeeFieldProps = {
  item: Sections_SanityInfoSnippetSection_items_SanityInfoSnippet_contact;
  raw: any;
  columnsAmount: string | null;
};

export const EmployeeField: React.FC<EmployeeFieldProps> = ({ item, raw, columnsAmount }) => {
  const locale = useLocale();
  const { colors } = useContext(ThemeContext);

  let fontSize = [2, 3];

  if (columnsAmount === '4') {
    fontSize = [2, 2];
  }

  if (!item) return null;
  return (
    <>
      {raw.text && <MiniBlockContentRenderer body={raw.text[locale]} />}
      {item.displayInfo?.includes('name') && (
        <Text fontSize={fontSize} fontWeight={300}>
          {item.employee?.contact?.firstName} {item.employee?.contact?.lastName}
        </Text>
      )}
      {item.displayInfo?.includes('role') && (
        <Text fontWeight={300} fontSize={fontSize}>{extractWithLocale(item.role ?? null, locale) ?? ''}</Text>
      )}
      {item.displayInfo?.includes('email') && (
        <Obfuscate style={{color: colors.text, fontWeight: 300}} email={item.employee?.contact?.email} />
      )}
      {item.displayInfo?.includes('phone') && (
        <Text display="block" fontSize={fontSize} fontWeight={300} color="text" as="a" href={`tel:${item.employee?.contact?.phone}`}>
          {item.employee?.contact?.phone}
        </Text>
      )}
    </>
  );
};
