import React, { FC, HTMLProps } from 'react';
import styled from 'styled-components';
import { Translations } from '../../i18n/translations';
import ErrorMessage from './ErrorMessage';

type InputProps = HTMLProps<HTMLTextAreaElement> & {
  touched?: boolean;
  error?: string;
  errorAbsolute?: boolean;
};

const TextArea: FC<InputProps> = ({ ref, as, touched, error, errorAbsolute, ...props }) => (
  <>
    <StyledTextArea {...props} />
    {touched && error && (
      <ErrorMessage error={error as keyof Translations} absolute={errorAbsolute} />
    )}
  </>
);

const StyledTextArea = styled.textarea`
  width: 100%;
  font-size: 18px;
  font-family: Rubik;
  padding: 7px 0 7px 10px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export default TextArea;
