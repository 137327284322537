import React from 'react';
import styled from 'styled-components';
import { Sections_SanityYoutubeSection } from '../../__generated__/graphql';
import { Box } from '../common/Box';

export type YouTubeSectionProps = {
  section: Sections_SanityYoutubeSection;
  autoplay: boolean;
};

export const YouTubeSection: React.FC<YouTubeSectionProps> = ({ section, autoplay }) => {
  const videoId = getId(section.youtubeUrl);

  const runVideo = autoplay ? '1' : '0';

  return (
    <IframeBox as="section" gridColumn={section.isFullWidth ? '1 / -1' : '2 / -2'}>
      <Iframe
        src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=${runVideo}`}
        frameBorder="0"
        allowFullScreen
        allow="autoplay"
      ></Iframe>
    </IframeBox>
  );
};

const IframeBox = styled(Box)`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const getId = (url: string | null) => {
  if (!url) return 'error';
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return 'error';
  }
};
