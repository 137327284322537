import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useInViewSpring } from '../../../hooks/useInViewSpring';
import { getGridColumnsFromAlignment } from '../../../utils/getGridColumnsFromAlignment';
import { GridItems_SanityImageGridImage } from '../../../__generated__/graphql';
import { Box } from '../../common/Box';
import { AnimatedBox, springAnimationWithDelay2 } from './gridHelpers';

export type ImageGridItemSectionProps = {
  section: GridItems_SanityImageGridImage;
  index: number;
};

export const ImageGridItemSection: React.FC<ImageGridItemSectionProps> = ({ section, index }) => {
  const [ref, springProps] = useInViewSpring({
    spring: springAnimationWithDelay2,
    inViewOptions: { triggerOnce: true },
    index,
  });

  const { padding } = section;

  if (section?.image?.asset?.gatsbyImageData == null) {
    return <div />;
  }
  return (
    <AnimatedBox
      style={springProps}
      gridColumn={getGridColumnsFromAlignment(section.alignment, { singleColumnOnMobile: true })}
    >
      <div ref={ref}>
        <Box p={padding ? [0, 3, 3, 6] : 0}>
          <GatsbyImage image={section?.image?.asset?.gatsbyImageData as any} />
          {/* <Egg eggNumber={1} /> */}
          {/* {index === 1 && <Egg eggNumber={2} />} */}
        </Box>
      </div>
    </AnimatedBox>
  );
};
