import React from 'react';
import * as Logos from '../../images/clients/logos';
import { bodyPadding } from '../../theme/theme';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { Sections_SanityLogoSection } from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { LineHeading } from '../common/LineHeading';
import { useLocale } from '../CurrentLocale';

export type LogoSectionProps = { section: Sections_SanityLogoSection };

export const LogoSection: React.FC<LogoSectionProps> = ({ section }) => {
  const locale = useLocale();

  const title = extractWithLocale(section.sectionTitle, locale);

  return (
    <Box as="section" gridColumn="2 / -2" px={bodyPadding} mt={4} pb={6}>
      {title && <LineHeading mb={4}>{title}</LineHeading>}
      <InnerGrid columns={[2, 4]} gridGap={3}>
        {section.logos?.map((logo, index) => {
          if (!logo) return null;
          return (
            <Box my={3} key={index} display="flex" justifyContent="flex-start" alignItems="center">
              {getLogo(logo)}
            </Box>
          );
        })}
      </InnerGrid>
    </Box>
  );
};

const getLogo = (client: string) => {
  switch (client) {
    case 'bmw':
      return <Logos.Bmw style={{ height: '60px' }} />;
    case 'coor':
      return <Logos.Coor style={{ height: '40px' }} />;
    case 'lrf':
      return <Logos.Lrf style={{ height: '40px' }} />;
    case 'lrfMedia':
      return <Logos.LrfMedia style={{ height: '35px' }} />;
    case 'jamtKraft':
      return <Logos.JamtKraft style={{ height: '50px' }} />;
    case 'moderna':
      return <Logos.Moderna style={{ height: '40px' }} />;
    case 'sca':
      return <Logos.Sca style={{ height: '40px' }} />;
    case 'ssab':
      return <Logos.Ssab style={{ height: '30px' }} />;
    default:
      return `Logo: ${client} not supported.`;
  }
};
