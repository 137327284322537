import React from 'react';
import { useIsMobile } from '../hooks/useIsMobile';
import { Box } from './common/Box';
import arrow from '../images/icons/link-arrow-orange.svg';
import styled from 'styled-components';

type TrackLengthProps = {
  length: string;
  darkVersion?: boolean;
};

const TrackLength: React.FC<TrackLengthProps> = ({ length, darkVersion }) => {
  const isMobile = useIsMobile();

  const height = isMobile ? 25 : 30;
  const width = isMobile ? 75 : 93;
  const fontSize = isMobile ? 12 : 14;

  if (darkVersion) {
    return (
      <Box
        style={{
          position: 'absolute',
          right: 10,
          bottom: 8,
          zIndex: 9,
          backgroundColor: 'black',
          color: 'white',
          height: height,
          width: isMobile ? 60 : 70,
          fontSize: isMobile ? 13 : 15,
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Box>{length}</Box>
      </Box>
    );
  }

  return (
    <Box
      style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        zIndex: 9,
        backgroundColor: 'white',
        height: height,
        width: width,
        fontSize: fontSize,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      <Icon />
      <Box mr={3}>{length}</Box>
    </Box>
  );
};

const Icon = styled(Box)`
  & {
    content: url(${arrow});
    height: 26px;
    width: 26px;
  }
`;

export default TrackLength;
