import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useInViewSpring } from '../../hooks/useInViewSpring';
import { useIsMobile } from '../../hooks/useIsMobile';
import { color } from '../../theme/color';
import { bodyPadding } from '../../theme/theme';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { getLinkTitle, getLocalizedLink, Link } from '../../utils/getLocalizedLink';
import { Sections_SanityInfoSnippetSection } from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { EmployeeField } from '../common/EmployeeField';
import { InnerGrid } from '../common/InnerGrid';
import { LineHeading } from '../common/LineHeading';
import { MiniBlockContentRenderer } from '../common/MiniBlockContentRenderer';
import { Text } from '../common/Text';
import { useLocale } from '../CurrentLocale';
import { InfoSnippetLinkList } from '../InfoSnippetLinkList';
import {
  AnimatedBox,
  springAnimation,
  springAnimationWithDelay2,
  springAnimationWithDelay3,
} from './grid/gridHelpers';
import { LinkWithArrrow } from '../common/LinkWithArrow';
import { ButtonMagnetic } from '../common/ButtonMagnetic';
import { PersonField, PersonFieldLink } from '../common/PersonField';
import { useTransition } from 'react-spring';
import { useTranslation } from '../../i18n/useTranslation';

interface InfoSnippetSectionProps {
  section: { raw?: any | null | undefined } & Sections_SanityInfoSnippetSection;
}

// TODO refactor, use some insnotnull and similar utils.
export const InfoSnippetSection: React.FC<InfoSnippetSectionProps> = ({ section }) => {
  const { colors } = useContext(ThemeContext);
  const locale = useLocale();
  const isMobile = useIsMobile();
  const t = useTranslation();

  const { items, raw, sectionTitle, columnsAmount } = section;

  if (!items) return null;

  const sectionText = raw?.sectionText;

  const columns = columnsAmount === '4' ? [1, 2, 4] : [1, 2];
  const gap = columnsAmount === '4' ? 3 : 3;

  const animation = isMobile
    ? springAnimation
    : columnsAmount === '4'
    ? springAnimationWithDelay3
    : springAnimationWithDelay2;

  const title = extractWithLocale(sectionTitle, locale);

  return (
    <Box gridColumn="2/-2" as="section" px={bodyPadding} pb={2}>
      {title && <LineHeading>{title}</LineHeading>}
      {sectionText && (
        <InnerGrid columns={[1, 2]} mb={[0, 4]} mt={[4, 0]}>
          <Box>
            <MiniBlockContentRenderer body={sectionText[locale] ?? []} />
          </Box>
        </InnerGrid>
      )}
      <InnerGrid mt={3} columns={columns} gridGap={gap} pb={4}>
        {items.map((item, index) => {
          if (!item || item.__typename === 'SanitySpacer')
            // @return spacer
            return <Box key={item?._key ?? index} />;

          if (item.__typename === 'SanityInfoSnippetLinkList') {
            return <InfoSnippetLinkList links={item.linkListLinks} />;
          }

          const rawItem = raw?.items.find((rawItem: any) => item._key === rawItem._key);

          const borderTop = item.borderTop !== 'none' ? item.borderTop : null;
          const borderColor = borderTop && borderTop !== 'text' ? color[borderTop] : undefined;

          const [ref, springProps] = useInViewSpring({
            spring: animation,
            index,
          });

          const marginTop =
            item.marginTop === 'medium'
              ? [5, '100px', '100px', '125px']
              : item.marginTop === 'big'
              ? [5, '200px', '200px', '250px']
              : item.marginTop === 'huge'
              ? [5, '300px', '300px', '375px']
              : 0;

          const employeeImg = item.contact?.employee?.contact?.image ?? null;
          const linksHeading = (item.links?.heading && item.links?.heading[locale]) ?? null;
          const buttonAlign =
            item.buttonAlign === 'right'
              ? 'flex-end'
              : item.buttonAlign === 'middle'
              ? 'center'
              : 'flex-start';

          const ButtonTag = item.buttonType === 'primary' ? ButtonMagnetic : LinkWithArrrow;

          const image = item.image;
          const fluid = image?.image?.asset?.gatsbyImageData;
          const imageLink = item.image?.link ? item.image?.link[0] : null;

          const isGif = (fluid?.src ?? '').includes('.gif');

          const fluidImage =
            fluid && isGif
              ? {
                  aspectRatio: fluid.aspectRatio,
                  fluidImage: fluid.src,
                  base64: fluid.base64,
                  sizes: fluid.sizes,
                  srcSet: fluid.src,
                }
              : fluid;

          const isPerson = !!item.person;

          if (isPerson) {
            const personImg = item.person?.person?.person?.image ?? null;
            const personLink =
              (locale === 'sv' ? '/people/' : '/en/people/') +
                item.person?.person?.person?.slug?.current || '';

            return (
              <AnimatedBox
                style={springProps}
                key={item._key ?? index}
                mt={marginTop}
                mb={4}
                borderTop={borderTop && `solid 3px ${borderColor || colors.text}`}
              >
                <div
                  ref={ref}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                  }}
                >
                  <Box>
                    {personImg && item.person?.displayInfo?.includes('image') && (
                      <Box mb={columnsAmount === '4' ? 2 : 4} height={333}>
                        <GatsbyImage
                          image={{
                            ...(personImg.asset?.gatsbyImageData as any),
                            layout: 'fixed',
                            height: 333,
                            width: '100%',
                          }}
                        />
                      </Box>
                    )}

                    {rawItem.snippetText && (
                      <MiniBlockContentRenderer body={rawItem.snippetText[locale]} />
                    )}

                    {item.person && (
                      <PersonField item={item.person} raw={rawItem} columnsAmount={columnsAmount} />
                    )}
                  </Box>

                  {item.person && (
                    <Box>
                      <PersonFieldLink link={personLink} translate={t} />
                    </Box>
                  )}
                </div>
              </AnimatedBox>
            );
          }

          return (
            <AnimatedBox
              style={springProps}
              key={item._key ?? index}
              mt={marginTop}
              mb={4}
              borderTop={borderTop && `solid 3px ${borderColor || colors.text}`}
            >
              <div ref={ref}>
                {image && imageLink ? (
                  <Box mb={4}>
                    <a
                      target={imageLink.__typename === 'SanityOutboundLink' ? '_blank' : undefined}
                      href={getLocalizedLink(imageLink, locale)}
                    >
                      <GatsbyImage image={fluidImage as any} />
                    </a>
                  </Box>
                ) : image ? (
                  <Box mb={4}>
                    <GatsbyImage image={(image.image?.asset?.gatsbyImageData as any) ?? ''} />
                  </Box>
                ) : null}
                {employeeImg && (
                  <Box mb={columnsAmount === '4' ? 2 : 4}>
                    <GatsbyImage image={(employeeImg.asset?.gatsbyImageData as any) ?? ''} />
                  </Box>
                )}
                {rawItem.snippetText && (
                  <MiniBlockContentRenderer body={rawItem.snippetText[locale]} />
                )}

                {item.contact && (
                  <EmployeeField item={item.contact} raw={rawItem} columnsAmount={columnsAmount} />
                )}

                {item.links && (
                  <Box>
                    {linksHeading && (
                      <Text
                        pt={3}
                        mb={4}
                        fontSize={[1, 1, 1, 4]}
                        fontWeight={400}
                        color={borderColor}
                      >
                        {extractWithLocale(item.links.heading, locale)}
                      </Text>
                    )}

                    {item.links?.links?.map(link => {
                      return (
                        <Box
                          display="flex"
                          justifyContent={buttonAlign}
                          mt={3}
                          key={link?._key ?? index}
                        >
                          <ButtonTag href={getLocalizedLink(link as Link, locale)}>
                            {getLinkTitle(link as Link, locale)}
                          </ButtonTag>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </div>
            </AnimatedBox>
          );
        })}
      </InnerGrid>
    </Box>
  );
};
