import React from 'react';
import { Box } from '../common/Box';
import { Sections_SanityTableSection } from '../../__generated__/graphql';
import { useLocale } from '../CurrentLocale';
import { Text } from '../common/Text';
import styled from 'styled-components';
import { extractWithLocale } from '../../utils/extractWithLocale';

export type TableSectionProps = {
  section: Sections_SanityTableSection;
};

export const TableSection: React.FC<TableSectionProps> = ({ section }) => {
  const locale = useLocale();

  const content = section.tableContent;
  const headings = section.columnHeadings;

  const localeContent = content && content[locale];

  if (!localeContent) return null;
  return (
    <Box as="section" gridColumn="2 / -2" my={3} px={4}>
      {localeContent.rows?.map((row, index) => {
        if (index === 0) {
          return (
            <Row key={row?._key || index} py={3}>
              {row?.cells?.map((cell, index) => {
                const heading = headings && extractWithLocale(headings[index], locale);
                return (
                  <Box key={index} display="flex" flexDirection="column" width="40%">
                    {heading && (
                      <Text fontWeight={500} color="#292929" fontSize={[2, 3]}>
                        {heading}
                      </Text>
                    )}
                    <Text fontWeight={300} color="#292929" fontSize={[2, 3]}>
                      {cell}
                    </Text>
                  </Box>
                );
              })}
            </Row>
          );
        }

        return (
          <Row key={row?._key || index} py={3}>
            {row?.cells?.map((cell, index) => {
              return (
                <Box key={index} width="40%">
                  <Text fontWeight={300} color="#292929" fontSize={[2, 3]}>
                    {cell}
                  </Text>
                </Box>
              );
            })}
          </Row>
        );
      })}
    </Box>
  );
};

const Row = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
`;
