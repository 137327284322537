import React from 'react';
import { Box } from '../common/Box';
import { Sections_SanityTextSection } from '../../__generated__/graphql';
import { BlockContentRenderer } from '../common/BlockContentRenderer';
import { bodyPadding } from '../../theme/theme';
import { useLocale } from '../CurrentLocale';

export type TextSectionProps = {
  section: { raw?: any | null | undefined } & Sections_SanityTextSection;
};

export const TextSection: React.FC<TextSectionProps> = ({ section }) => {
  const locale = useLocale();

  const content = section.raw.content;

  if (!content) return null;
  return (
    <Box as="section" gridColumn="2 / -2" my={3} padding={bodyPadding}>
      <BlockContentRenderer body={content[locale]} />
    </Box>
  );
};
