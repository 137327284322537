import { ResizeObserver as polyfill } from '@juggle/resize-observer';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { animated, config, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';
import { useInViewSpring, UseInViewSpringProps } from '../hooks/useInViewSpring';
import { useIsMobile } from '../hooks/useIsMobile';
import { color } from '../theme/color';
import { themeTransition } from '../theme/themeTransition';
import { AspectBox } from './common/AspectBox';
import { Box } from './common/Box';
import { Text } from './common/Text';
import { AnimatedBox, springAnimation } from './sections/grid/gridHelpers';
import { ReactComponent as ArrowIcon } from '../images/icons/link-arrow-orange.svg';
import calendar from '../images/icons/calendar.svg';
import { Grid } from './common/Grid';
import TrackLength from './TrackLength';
import { getFixedGifSrc } from '../utils/getFixedGifSrc';

const imageScale = (x: number, y: number, s: number) => {
  const scale = s < 1 ? 1 : s;
  return `translate(${y}px, ${x}px) scale(${scale})`;
};

export interface TalkTeaserTag {
  title: string;
  link: string;
}

export type TalkTeaserProps = {
  isFeatured: boolean | null;
  kicker?: string;
  title: string;
  preamble: string | null;
  fluidImage?: any;
  link: string;
  index: number;
  eventDate: Date | null;
  imageAltText: string;
  tags?: TalkTeaserTag[];
  tagPosition?: 'top' | 'bottom';
  videoLength?: string;
  gridColumn?: string | string[];
  gridRow?: string | string[];
  mb?: number | number[];
  mt?: any;
  desktopSpringAnimation?: UseInViewSpringProps['spring'];
};

export const TalkTeaser: React.FC<TalkTeaserProps> = ({
  isFeatured,
  kicker,
  title,
  preamble,
  fluidImage,
  imageAltText,
  link,
  index,
  eventDate,
  videoLength,
  desktopSpringAnimation = springAnimation,
  gridColumn = ['span 4', 'span 4', 'auto', 'auto'],
  gridRow = 'auto',
  mb = [4, 3],
  mt = [0],
}) => {
  const isMobile = useIsMobile();
  const [ref, springProps] = useInViewSpring({
    spring: isMobile ? springAnimation : desktopSpringAnimation,
    index,
  });
  const [measureRef] = useMeasure({ scroll: true, debounce: 100, polyfill });

  const [imageStyle] = useSpring(() => ({
    xys: [0, 0, 1],
    config: config.stiff,
  }));

  const hasEventPassed = eventDate && eventDate < new Date();
  const width = isFeatured ? ['1 / -1'] : gridColumn;
  const gridWidth = isFeatured ? ['1fr', '2fr 1fr'] : '1fr';
  const contentPadding = isFeatured ? [0, 4] : 0;

  fluidImage = getFixedGifSrc(fluidImage);

  return (
    <AnimatedBox
      style={springProps}
      gridColumn={width}
      gridRow={gridRow}
      zIndex={1}
      mb={mb}
      mt={mt}
    >
      <div ref={ref}>
        <CaseLink to={link}>
          <Grid gridTemplateColumns={gridWidth}>
            <AnimatedBox
              mb={isFeatured ? 0 : 3}
              pr={contentPadding}
              style={{ overflow: 'hidden' }}
              ref={measureRef}
            >
              <Box style={{ position: 'relative' }}>
                {videoLength && <TrackLength length={videoLength} />}
                {fluidImage ? (
                  <AnimatedImg
                    image={{ ...fluidImage, aspectRatio: 5 / 3 }}
                    style={{ transform: imageStyle.xys.interpolate(imageScale as any) }}
                    alt={imageAltText}
                  />
                ) : (
                  <AspectBox aspectRatio={360 / 600} />
                )}
              </Box>
            </AnimatedBox>
            <Box pr={contentPadding}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {kicker && (
                  <Text
                    fontSize={[3, 24]}
                    pl={'2px'}
                    fontFamily="Handsome"
                    style={{ display: 'inline-flex' }}
                  >
                    {kicker}
                  </Text>
                )}
                {eventDate && !hasEventPassed && (
                  <Box
                    ml={[3]}
                    style={{ display: 'inline-flex', position: 'relative', top: 2, right: 4 }}
                  >
                    <CalendarIcon />
                    <Text ml={[4]} style={{ fontWeight: 500, fontSize: 14 }}>
                      {eventDate.toLocaleDateString('default', { day: '2-digit', month: 'short' })}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box mt={0} ml={-1} maxWidth="95%">
                <CaseTitle
                  display="inline"
                  fontSize={3}
                  fontWeight={500}
                  lineHeight={1}
                  p={1}
                  pt={0}
                  letterSpacing="-0.02em"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  {title}
                </CaseTitle>
              </Box>
              <Box mt={1}>
                <Text fontSize={[1, 16]} fontWeight={300}>
                  {preamble}
                </Text>
              </Box>
              <Box mt={[2, 3]}>
                <ArrowAnchor />
              </Box>
            </Box>
          </Grid>
        </CaseLink>
      </div>
    </AnimatedBox>
  );
};

const CaseTitle = styled(Text)`
  ${themeTransition('background-color', 0.3)}
  position: relative;
`;

const CaseLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover ${CaseTitle} {
    background-color: ${color.orange};
  }
`;

const AnimatedImg = animated(GatsbyImage);

const ArrowAnchor = styled(ArrowIcon)`
  width: 30px;
  stroke: #000;
  fill: none;
`;

const CalendarIcon = styled(Box)`
  &::before {
    content: url(${calendar});
    position: absolute;
    width: 1.2em;
    margin-left: 0.5em;
  }
`;
