import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import { color } from '../../theme/color';
import { useLocale } from '../CurrentLocale';
import { H1, H2, H3, H4, H5 } from './Heading';
import { LocaleSolidBlockContentRenderer } from './LocaleSolidBlockContentRenderer';
import { BlueLink } from './StyledLink';
import { Text } from './Text';

export type BlockContentRendererProps = {
  body: any;
};

const serializers = {
  types: {
    block: (props: any) => {
      switch (props.node.style) {
        case 'h1':
          return <H1 {...props} />;
        case 'h2':
          return <H2 {...props} color="#111" />;
        case 'h3':
          return <H3 {...props} />;
        case 'h4':
          return <H4 {...props} />;
        case 'h5':
          return <H5 {...props} />;
        case 'normal':
          return (
            <Text
              {...props}
              as="p"
              fontWeight={300}
              color="#292929"
              fontSize={[2, 3]}
              mb={3}
              mt={0}
              maxWidth={900}
            />
          );
      }
      return <div {...props} />;
    },
    localeSolidBlockContent: (props: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const locale = useLocale();
      return <LocaleSolidBlockContentRenderer body={props.node[locale]} />
    }
  },
  marks: {
    strong: (props: any) => {
      return <Text as="strong" fontWeight={500} {...props} />;
    },
    color: (props: any) => {
      return <span style={{ color: color[props.mark.color] }} {...props} />;
    },
    link: (props: any) => {
      return <BlueLink href={props.mark.href} {...props} />;
    },
  },
};

export const BlockContentRenderer: React.FC<BlockContentRendererProps> = ({ body }) => {
  return <BlockContent blocks={body} serializers={serializers} />;
};
