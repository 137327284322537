import React from 'react';
import {
  Sections_SanityMultiColumnSection,
  Sections_SanityMultiColumnSection_columns_SanityMultiColumnLargeNumber,
} from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { Text } from '../common/Text';
import { useLocale } from '../CurrentLocale';

export type MultiColumnSectionProps = {
  section: Sections_SanityMultiColumnSection;
};

export const MultiColumnSection: React.FC<MultiColumnSectionProps> = ({ section }) => {
  return (
    <InnerGrid as="section" columns={[1, 2, 4]}>
      {section.columns?.map(column => {
        switch (column?.__typename) {
          case 'SanityMultiColumnLargeNumber':
            return <MultiColumnLargeNumber key={column._key ?? ''} column={column} />;
          default:
            return <div />;
        }
      })}
    </InnerGrid>
  );
};

const MultiColumnLargeNumber: React.FC<{
  column: Sections_SanityMultiColumnSection_columns_SanityMultiColumnLargeNumber;
}> = ({ column }) => {
  const locale = useLocale();
  return (
    <Box borderTop="solid 3px #000" pt={4}>
      <Text fontWeight={500} fontSize={4}>
        {column.heading?.[locale]}
      </Text>
      <Text fontWeight={500} fontSize={6}>
        {column.number}
      </Text>
    </Box>
  );
};
