import { ArticleQuery_sanityArticle_image_asset_fluid } from "../__generated__/graphql";

export const getFixedGifSrc = (data: ArticleQuery_sanityArticle_image_asset_fluid | null | undefined) => {
  if (Array.isArray(data)) {
    return data.map(item => getFixedGifSrc(item));
  } else if (typeof data == 'object' && !!data?.src && data.src.includes('.gif')) {
    return { ...data, srcWebp: data.src, srcSetWebp: data.srcSet };
  } else {
    return data;
  }
}