import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import { color } from '../../theme/color';
import { H1, H2, H3, H4, H5 } from './Heading';
import { BlueLink } from './StyledLink';
import { Text } from './Text';

export type LocaleSolidBlockContentRendererProps = {
  body: any;
};

const serializers = {
  types: {
    block: (props: any) => {
      switch (props.node.style) {
        case 'h1':
          return <H1 {...props} mb={0} />;
        case 'h2':
          return <H2 {...props} mb={0} color="#111" />;
        case 'h3':
          return <H3 {...props} mb={0} />;
        case 'h4':
          return <H4 {...props} mb={0} />;
        case 'h5':
          return <H5 {...props} mb={0} />;
        case 'normal':
          return (
            <Text
              {...props}
              as="p"
              fontWeight={300}
              color="#292929"
              fontSize={[2, 3]}
              mb={0}
              mt={0}
              maxWidth={900}
            />
          );
      }
      return <div {...props} />;
    },
  },
  marks: {
    strong: (props: any) => {
      return <Text as="strong" fontWeight={500} {...props} />;
    },
    color: (props: any) => {
      return <span style={{ color: color[props.mark.color] }} {...props} />;
    },
    link: (props: any) => {
      return <BlueLink href={props.mark.href} {...props} />;
    },
  },
};

export const LocaleSolidBlockContentRenderer: React.FC<LocaleSolidBlockContentRendererProps> = ({ body }) => {
  return <BlockContent blocks={body} serializers={serializers} />;
};
