import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useInViewSpring } from '../../../hooks/useInViewSpring';
import { getGridColumnsFromAlignment } from '../../../utils/getGridColumnsFromAlignment';
import { GridItems_SanityImageAndTextSection } from '../../../__generated__/graphql';
import { Box } from '../../common/Box';
import { AnimatedBox, springAnimationWithDelay2 } from './gridHelpers';
import { Text } from '../../common/Text';
import { extractWithLocale } from '../../../utils/extractWithLocale';
import { useLocale } from '../../CurrentLocale';

export type ImageAndTextSectionProps = {
  section: GridItems_SanityImageAndTextSection;
  index: number;
};

export const ImageAndTextSection: React.FC<ImageAndTextSectionProps> = ({ section, index }) => {
  const locale = useLocale();
  const [ref, springProps] = useInViewSpring({
    spring: springAnimationWithDelay2,
    inViewOptions: { triggerOnce: true },
    index,
  });

  const { padding } = section;
  const body = extractWithLocale(section.body, locale);
  const lines = body?.split('\n') ?? [];

  if (section?.image?.asset?.gatsbyImageData == null) {
    return <div />;
  }
  return (
    <AnimatedBox
      style={springProps}
      gridColumn={getGridColumnsFromAlignment(section.alignment, { singleColumnOnMobile: true })}
    >
      <div ref={ref}>
        <Box p={padding ? [0, 3, 3, 6] : 0}>
          <GatsbyImage image={section?.image?.asset?.gatsbyImageData as any} />
        </Box>
        {lines.map(line => {
          if (line === '') return null;

          return (
            <Text key={line} as="p" fontWeight={300}>
              {line}
            </Text>
          );
        })}
      </div>
    </AnimatedBox>
  );
};
