import React from 'react';
import styled from 'styled-components';

import play from '../images/audio/play.svg';
import headphones from '../images/audio/headphones.svg';

interface Props {
  type: 'audio' | 'video';
}

const PlayTeaserOverlay: React.FC<Props> = ({ type }) => {
  const icon = type === 'audio' ? headphones : play;

  return (
    <StyledWrapper>
      <StyledIcon src={icon} />
    </StyledWrapper>
  );
};

export default PlayTeaserOverlay;

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    height: 83px;
    width: 83px;
    background-color: #99999955;
    backdrop-filter: blur(4px);
    border-radius: 50%;

    @media (max-width: 500px) {
      height: 66px;
      width: 66px;
    }
  }
`;

const StyledIcon = styled.img`
  position: absolute;
`;
