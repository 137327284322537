import React, { useContext } from 'react';
import { getGridColumnsFromAlignment } from '../../../utils/getGridColumnsFromAlignment';
import { GridItems_SanityLineSection } from '../../../__generated__/graphql';
import { Box } from '../../common/Box';
import { ThemeContext } from 'styled-components';

export type LineSectionProps = {
  section: GridItems_SanityLineSection;
};

export const LineSection: React.FC<LineSectionProps> = ({ section }) => {

  const { colors } = useContext(ThemeContext);

  return (
    <Box
      as="section"
      gridColumn={getGridColumnsFromAlignment(section.alignment, { singleColumnOnMobile: true })}
    >
      <Box mb={1} borderTop={`solid 2px ${colors.text}`}>
        <></>
      </Box>
    </Box>
  );
};
