import { ResizeObserver as polyfill } from '@juggle/resize-observer';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { animated, config, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';
import { useInViewSpring, UseInViewSpringProps } from '../hooks/useInViewSpring';
import { useIsMobile } from '../hooks/useIsMobile';
import { color } from '../theme/color';
import { themeTransition } from '../theme/themeTransition';
import { AspectBox } from './common/AspectBox';
import { Box } from './common/Box';
import { Text } from './common/Text';
import { AnimatedBox, springAnimation } from './sections/grid/gridHelpers';
import { Grid } from './common/Grid';
import TrackLength from './TrackLength';
import { getFixedGifSrc } from '../utils/getFixedGifSrc';
import PlayTeaserOverlay from './PlayTeaserOverlay';
import { ReactComponent as ArrowIcon } from '../images/icons/link-arrow-orange.svg';

const imageScale = (x: number, y: number, s: number) => {
  const scale = s < 1 ? 1 : s;
  return `translate(${y}px, ${x}px) scale(${scale})`;
};

export interface TeaserTag {
  title: string;
  link: string;
}

export type TeaserProps = {
  isFeatured: boolean | null;
  kicker?: string;
  title: string;
  preamble: string | null;
  fluidImage?: any;
  link: string;
  index: number;
  imageAltText: string;
  tags?: TeaserTag[];
  tagPosition?: 'top' | 'bottom';
  trackLength?: string;
  gridColumn?: string | string[];
  gridRow?: string | string[];
  mb?: number | number[];
  mt?: any;
  desktopSpringAnimation?: UseInViewSpringProps['spring'];
  padding?: number | number[];
  backgroundColor?: string;
  type: 'audio' | 'video';
  isPlay?: boolean;
};

export const PlayTeaser: React.FC<TeaserProps> = ({
  isFeatured,
  kicker,
  title,
  preamble,
  fluidImage,
  imageAltText,
  link,
  index,
  trackLength,
  desktopSpringAnimation = springAnimation,
  gridColumn = ['span 4', 'span 4', 'auto', 'auto'],
  gridRow = 'auto',
  mb = [4, 0],
  mt = [0],
  padding,
  backgroundColor,
  type,
  isPlay,
}) => {
  const isMobile = useIsMobile();
  const [ref, springProps] = useInViewSpring({
    spring: isMobile ? springAnimation : desktopSpringAnimation,
    index,
  });
  const [measureRef] = useMeasure({ scroll: true, debounce: 100, polyfill });

  const [imageStyle] = useSpring(() => ({
    xys: [0, 0, 1],
    config: config.stiff,
  }));

  const width = isFeatured ? ['1 / -1'] : gridColumn;
  const gridWidth = isFeatured ? ['1fr', '2fr 1fr'] : '1fr';
  const wrapperPadding = isFeatured ? 0 : [0, 4];
  const contentPadding = isFeatured ? [0, 4] : 0;

  fluidImage = getFixedGifSrc(fluidImage);

  return (
    <AnimatedBox
      style={springProps}
      gridColumn={width}
      gridRow={gridRow}
      pr={padding || wrapperPadding}
      p={padding || null}
      zIndex={1}
      mb={mb}
      mt={mt}
      backgroundColor={backgroundColor}
    >
      <div ref={ref}>
        <CaseLink to={link} hoverColorReversed={!!backgroundColor}>
          <Grid gridTemplateColumns={gridWidth}>
            <PreviewBox mb={0} pr={contentPadding} style={{ overflow: 'hidden' }} ref={measureRef}>
              {trackLength && <TrackLength length={trackLength} darkVersion={true} />}
              {fluidImage ? (
                <AnimatedImg
                  image={{ ...fluidImage, aspectRatio: 5 / 3 }}
                  style={{ transform: imageStyle.xys.interpolate(imageScale as any) }}
                  alt={imageAltText}
                />
              ) : (
                <AspectBox aspectRatio={360 / 600} />
              )}
              <PlayTeaserOverlay type={type} />
            </PreviewBox>
            <Box pr={contentPadding}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {kicker && (
                  <Text
                    fontSize={[3, 26]}
                    mt={[2]}
                    fontFamily="Handsome"
                    style={{ display: 'inline-flex' }}
                    color={(backgroundColor && color.black) || undefined}
                  >
                    {!isPlay && '#'}
                    {kicker}
                  </Text>
                )}
              </Box>
              <Box mt={0} ml={-1} maxWidth="95%">
                <CaseTitle
                  display="inline"
                  fontSize={[2, 3, 2, 3, 30]}
                  fontWeight={500}
                  lineHeight={1}
                  p={1}
                  pt={0}
                  letterSpacing="-0.02em"
                  style={{
                    textDecoration: 'none',
                  }}
                  color={(backgroundColor && color.black) || undefined}
                >
                  {title}
                </CaseTitle>
              </Box>
              <Box mt={3}>
                <Text fontSize={[1, 18]} fontWeight={300} color={color.black}>
                  {preamble}
                </Text>
              </Box>
              <Box mt={[3, 4]} maxWidth="95%">
                <ArrowAnchor invertColor={!backgroundColor} />
              </Box>
            </Box>
          </Grid>
        </CaseLink>
      </div>
    </AnimatedBox>
  );
};

const CaseTitle = styled(Text)`
  ${themeTransition('background-color', 0.3)}
  position: relative;
`;

const CaseLink = styled(Link) <{ hoverColorReversed: boolean }>`
  color: inherit;
  text-decoration: none;

  &:hover ${CaseTitle} {
    background-color: ${props => (props.hoverColorReversed ? '#ddd' : color.gray)};
  }
`;

const AnimatedImg = animated(GatsbyImage);

const PreviewBox = styled(AnimatedBox)`
  position: relative;
  color: black;
`;

const ArrowAnchor = styled(ArrowIcon)<{ invertColor: boolean }>`
  width: 30px;
  stroke: #000;
  fill: none;
  filter: invert(${props => props.invertColor ? '1' : '0'});
`;
