import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { bodyPadding } from '../../theme/theme';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { CaseListQuery, Sections_SanityCaseListSection } from '../../__generated__/graphql';
import { CaseFloatingTeaser, CaseGridTeaser } from '../CaseTeaser';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { LineHeading } from '../common/LineHeading';
import { useLocale } from '../CurrentLocale';

export type CaseListSectionProps = {
  section: Sections_SanityCaseListSection;
};

// TODO: Kika om det går att ändra antalet streck per sida. Gärna animera mellan.
export const CaseListSection: React.FC<CaseListSectionProps> = ({ section }) => {
  const data = useStaticQuery<CaseListQuery>(query);
  const locale = useLocale();

  const cases = data.cases.nodes.filter(i => extractWithLocale(i.slug, locale) !== null);

  const heading = extractWithLocale(section.heading, locale);

  const TeaserComponent = section.layout === 'grid' ? CaseGridTeaser : CaseFloatingTeaser;

  return (
    <InnerGrid as="section" px={bodyPadding} pb={6}>
      {heading && (
        <Box gridColumn="1 / -1">
          <LineHeading>{heading}</LineHeading>
        </Box>
      )}
      <Box gridColumn="1 / -1">
        <InnerGrid columns={section.layout === 'grid' ? 3 : 4}>
          {cases.map((caseStudy, index) => {
            return (
              <TeaserComponent
                key={caseStudy.id}
                caseStudy={caseStudy}
                index={index}
                locale={locale}
              />
            );
          })}
        </InnerGrid>
      </Box>
    </InnerGrid>
  );
};

const query = graphql`
  query CaseListQuery {
    cases: allSanityCase(
      sort: { fields: publishDate, order: DESC }
      filter: { isVisibleOnList: { ne: false } }
    ) {
      nodes {
        ...CaseTeaser
      }
    }
  }

  fragment CaseTeaser on SanityCase {
    __typename
    id
    heading {
      en
      sv
    }
    slug {
      en {
        current
      }
      sv {
        current
      }
    }
    client {
      name
    }
    isVisibleOnList
    image {
      asset {
        gatsbyImageData(width: 600, height: 375)
      }
    }
    imageAltText {
      sv
      en
    }
    tags {
      id
      title
      slug {
        en {
          current
        }
        sv {
          current
        }
      }
    }
    teaser {
      teaserHeading {
        en

        sv
      }

      teaserImage {
        asset {
          gatsbyImageData(width: 600, height: 375)
        }
      }
      imageAltText {
        sv
        en
      }
    }
  }
`;
