import React from 'react';
import styled from 'styled-components';
import { border, BorderProps, height, HeightProps, width, WidthProps } from 'styled-system';
import { useInViewSpring } from '../../hooks/useInViewSpring';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useTranslation } from '../../i18n/useTranslation';
import esattoMap from '../../images/map/esatto-map.svg';
import { color } from '../../theme/color';
import { Sections_SanityMapSection } from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { Text } from '../common/Text';
import { AnimatedBox, springAnimation, springAnimationWithDelay2 } from './grid/gridHelpers';

interface MapSectionProps {
  section: Sections_SanityMapSection;
}

export const MapSection: React.FC<MapSectionProps> = ({ section }) => {
  const t = useTranslation();

  const isMobile = useIsMobile();

  const [ref, springProps] = useInViewSpring({
    spring: isMobile ? springAnimation : springAnimationWithDelay2,
  });

  const isSSR = typeof window === 'undefined';

  return (
    <AnimatedBox
      as="section"
      style={springProps}
      gridColumn={['2 / -2', '2 / -2', '2 / -1', '2 / -1']}
      pb={6}
    >
      <div ref={ref}>
        <Box display={['none', 'flex']} justifyContent="flex-end" position="relative" my={4}>
          <MapIndicator
            top={['27%', '31%', '31%', '34%']}
            right="25%"
            width={['70%', '70%', '70%', '85%']}
            text={t('sundsvall')}
          />
          <MapIndicator
            top={['35%', '40%', '40%', '42.5%']}
            right="24.5%"
            width={['50%', '50%', '50%', '65%']}
            text={t('stockholm')}
          />
          <MapIndicator
            top={['57%', '63%', '63%', '66%']}
            right="23.6%"
            width={['65%', '65%', '65%', '75%']}
            text={t('krakow')}
          />
          <MapWrapper>
            {!isSSR && !isMobile && (
              <img src={esattoMap} alt="Map of europe with Esatto's offices marked out" />
            )}
          </MapWrapper>
        </Box>
      </div>
    </AnimatedBox>
  );
};

const MapWrapper = styled.div`
  width: 50%;
  svg {
    fill: ${(props) => props.theme.colors.mapFill};
  }
`;

const MapIndicator: React.FC<{
  top: string[] | string;
  right: string[] | string;
  width: string[] | string;
  text: string | null;
}> = ({ top, right, width, text }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="flex-end"
    zIndex={2}
    position="absolute"
    top={top}
    right={right}
    width={width}
  >
    <Text mr={2}>{text}</Text>
    <MapIndicatorLine borderTopWidth={['1px', '1px', '1px', '1px', '3px']} />
    <MapIndictorDot
      width={['10px', '10px', '10px', '15px', '25px']}
      height={['10px', '10px', '10px', '15px', '25px']}
    />
  </Box>
);

const MapIndicatorLine = styled.div<BorderProps>`
  ${border};
  border-top: solid ${color.green};
  width: 75%;
`;

type DotProps = HeightProps & WidthProps;

const MapIndictorDot = styled.div<DotProps>`
  ${height};
  ${width};
  border-radius: 50%;
  background-color: ${color.green};
`;
