import React, { FC } from 'react';
import { bodyPadding } from '../../theme/theme';
import { Box } from '../common/Box';
import { Button } from '../common/Button';
import { H2 } from '../common/Heading';
import { Sections_SanityCookiesSettingsSection } from '../../__generated__/graphql';
import { MiniBlockContentRenderer } from '../common/MiniBlockContentRenderer';
import { useLocale } from '../CurrentLocale';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { useContext } from 'react';
import { GlobalContext } from '../GlobalContext';

interface CookiesSettingsSectionProps {
  section: { raw?: any | null | undefined } & Sections_SanityCookiesSettingsSection;
}

export const CookiesSettingsSection: FC<CookiesSettingsSectionProps> = ({ section }) => {
  const locale = useLocale();
  const { dispatch } = useContext(GlobalContext);

  const openGDPRModal = () => dispatch({ type: 'isGDPRModalOpen', payload: true });

  const { title, text, button } = section;

  return (
    <Box gridColumn="2/-2" as="section" px={bodyPadding} pb={6}>
      <H2>{extractWithLocale(title, locale)}</H2>
      <MiniBlockContentRenderer body={extractWithLocale(text, locale)} />
      <Button onClick={openGDPRModal} style={{ marginTop: 20 }}>
        {extractWithLocale(button, locale)}
      </Button>
    </Box>
  );
};
