import styled from 'styled-components';
import Input from './Input';
import TextArea from './TextArea';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormItem = styled.p<{ mb?: number | string }>`
  position: relative;
  font-size: 18px;
  margin: ${({ mb }) => `0 0 ${mb || '10px'} 0`};
`;

export const StyledInput = styled(Input)`
  border: 1px solid #111;
  padding: 1rem;
  padding-left: 2rem;
  color: #111;
  font-size: 16px;

  &::placeholder {
    color: #333;
  }

  &::focus {
    outline: 0;
  }
`;

export const StyledTextArea = styled(TextArea)`
  border: 1px solid #111;
  padding: 1rem;
  padding-left: 2rem;
  color: #111;
  font-size: 16px;
  resize: none;
  height: 100%;

  &::placeholder {
    color: #333;
  }

  &::focus {
    outline: 0;
  }
`;
