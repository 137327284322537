import { useFormik } from 'formik';
import React, { FC, useContext, useMemo, useState } from 'react';
import * as yup from 'yup';
import styled, { ThemeContext } from 'styled-components';

import { extractWithLocale } from '../../utils/extractWithLocale';
import { Sections_SanityFormSection } from '../../__generated__/graphql';
import { useLocale } from '../CurrentLocale';

import { Box } from '../common/Box';
import { ButtonHover } from '../common/ButtonHover';
import { FormItem, StyledForm, StyledInput, StyledTextArea } from '../common/Form';
import { Grid } from '../common/Grid';
import { LineHeading } from '../common/LineHeading';
import { useWindowClass } from '../../hooks/useIsMobile';
import { useTranslation } from '../../i18n/useTranslation';
import { H2 } from '../common/Heading';

interface Props {
  section: Sections_SanityFormSection;
}

const getFormInitialValues = (fields: Sections_SanityFormSection['items']) => {
  if (!fields?.length) {
    return {};
  }

  return fields.reduce((acc, item) => {
    if (!item?.label) {
      return acc;
    }

    return { ...acc, [item.label.en || '']: '' };
  }, {});
};

const getValidationSchema = (fields: Sections_SanityFormSection['items']) => {
  if (!fields?.length) {
    return {};
  }

  const result = fields.reduce((acc, item) => {
    if (!item?.label?.en) {
      return acc;
    }

    let validator = yup.string();

    if (item.type === 'email') {
      validator = validator.email('incorrectEmail');
    }

    if (item.type === 'tel') {
      validator = validator.matches(/^[+?\d][\d|\-| ]{6,}$/, { message: 'incorrectPhone' });
    }

    if (item.required) {
      validator = validator.required('fieldRequired');
    }

    return { ...acc, [item.label.en]: validator };
  }, {});

  return yup.object(result);
};

const FormSection: FC<Props> = ({ section }) => {
  const locale = useLocale();
  const device = useWindowClass();
  const t = useTranslation();
  const { colors } = useContext(ThemeContext);

  const [success, setSuccess] = useState(false);

  const initialValues = useMemo(() => getFormInitialValues(section.items), [section.items]);
  const validationSchema = useMemo(() => getValidationSchema(section.items), [section.items]);

  const form = useFormik<Record<string, string>>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await fetch('/api/contact', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            sendTo: section.recipient,
            From: window.location.href,
            ...values,
          }),
        });

        setSubmitting(false);
        setSuccess(true);
        resetForm();

        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } catch (error) {
        alert(error);
      }
    },
  });

  const isSingleFieldForm = section.items?.length === 1 || undefined;
  const gridTemplateColumns = isSingleFieldForm
    ? ['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']
    : ['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)'];

  const gridTemplateRows = isSingleFieldForm
    ? ['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(1, 1fr)']
    : ['repeat(4, 1fr)', 'repeat(4, 1fr)', 'repeat(2, 1fr)'];

  return (
    <Box
      display="flex"
      flexDirection="column"
      as="section"
      mt={4}
      pb={4}
      px={[3, 3, 0]}
      gridColumn="2 / -2"
      paddingTop={isSingleFieldForm && 39}
      borderTop={isSingleFieldForm && `2px solid ${colors.text}`}
    >
      {!isSingleFieldForm && <LineHeading>{extractWithLocale(section.title, locale)}</LineHeading>}

      <StyledForm onSubmit={form.handleSubmit}>
        <Grid
          gridTemplateColumns={gridTemplateColumns}
          gridTemplateRows={gridTemplateRows}
          gridRowGap="32px"
          gridColumnGap="50px"
          mb={isSingleFieldForm ? 2 : 4}
        >
          {isSingleFieldForm && (
            <SingleFieldHeading>{extractWithLocale(section.title, locale)}</SingleFieldHeading>
          )}
          {section.items?.map((item, _, items) => {
            const name = item?.label?.en;

            if (!name) {
              return null;
            }

            if (item.type === 'textArea') {
              return (
                <FormItem
                  key={item._key}
                  style={{
                    gridColumn: 'span 2',
                    gridRow: 'span 2',
                    order: device !== 'desktop' ? items.length : 'initial',
                  }}
                  mb={0}
                >
                  <StyledTextArea
                    name={name}
                    value={form.values[name]}
                    error={form.errors[name]}
                    touched={form.touched[name]}
                    placeholder={extractWithLocale(item.label, locale) || ''}
                    onChange={form.handleChange}
                    errorAbsolute
                  />
                </FormItem>
              );
            }

            return (
              <FormItem key={item._key} mb={0}>
                <StyledInput
                  name={name}
                  value={form.values[name]}
                  error={form.errors[name]}
                  touched={form.touched[name]}
                  placeholder={extractWithLocale(item.label, locale) || ''}
                  onChange={form.handleChange}
                  errorAbsolute
                />
              </FormItem>
            );
          })}
          {isSingleFieldForm && (
            <ButtonHover
              type="submit"
              style={{ maxWidth: '300px', width: '100%', height: '53px' }}
              disabled={form.isSubmitting}
            >
              {success ? t('sent') : extractWithLocale(section.buttonLabel, locale)}
            </ButtonHover>
          )}
        </Grid>

        {!isSingleFieldForm && (
          <ButtonHover
            type="submit"
            style={{ marginLeft: 'auto', maxWidth: 'min(50%, 288px)', width: '100%' }}
            disabled={form.isSubmitting}
          >
            {success ? t('sent') : extractWithLocale(section.buttonLabel, locale)}
          </ButtonHover>
        )}
      </StyledForm>
    </Box>
  );
};

export default FormSection;

const SingleFieldHeading = styled(H2)`
  @media screen and (max-width: 55em) {
    grid-column: 1 / 3;
  }
`;
