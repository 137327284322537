import { IntersectionOptions, useInView } from 'react-intersection-observer';
import { AnimatedValue, useSpring, UseSpringProps } from 'react-spring';

export type UseInViewSpringProps = {
  spring?: (inView: boolean, index: number) => UseSpringProps<any>;
  inViewOptions?: IntersectionOptions;
  index?: number;
};

const defaultSpring: UseInViewSpringProps['spring'] = inView => ({
  opacity: inView ? 1 : 0,
  transform: inView ? 'translateY(0px)' : 'translateY(200px)',
});

const defaultInViewOptions: UseInViewSpringProps['inViewOptions'] = {
  triggerOnce: true,
};

export const useInViewSpring = (
  options: UseInViewSpringProps = {}
): [React.Ref<any>, AnimatedValue<any>, boolean] => {
  const spring = options.spring ?? defaultSpring;
  const inViewOptions = options.inViewOptions ?? defaultInViewOptions;
  const index = options.index ?? 0;

  const [ref, inView] = useInView(inViewOptions);
  const springProps = useSpring(spring(inView, index));

  return [ref, springProps, inView];
};
