import React from 'react';
import styled from 'styled-components';
import { Sections_SanityEmbeddedVideoSection } from '../../__generated__/graphql';
import { Box } from '../common/Box';

export type VimeoSectionProps = {
  section: Sections_SanityEmbeddedVideoSection;
  autoplay: boolean;
};

export const VimeoSection: React.FC<VimeoSectionProps> = ({ section, autoplay }) => {
  const videoId = getId(section.videoUrl);

  const runVideo = autoplay ? '1' : '0';

  return (
    <IframeBox as="section" gridColumn={section.isFullWidth ? '1 / -1' : '2 / -2'}>
      <Iframe
        src={`https://player.vimeo.com/video/${videoId}?autoplay=${runVideo}`}
        frameBorder="0"
        allowFullScreen
        allow="autoplay"
      ></Iframe>
    </IframeBox>
  );
};

const IframeBox = styled(Box)`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const getId = (url: string | null) => {
  if (!url) return 'error';
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const match = url.match(regExp);

  if (match && match[5].length > 0) {
    return match[5];
  } else {
    return 'error';
  }
};
