import React from 'react';
import { Box, BoxProps } from './Box';

export type AspectBoxProps = BoxProps & { aspectRatio: number; children?: React.ReactNode };

export const AspectBox = ({ aspectRatio, children, ...props }: AspectBoxProps) => {
  return (
    <Box pb={aspectRatio * 100 + '%'} position="relative">
      <Box bg="primary" position="absolute" top={0} left={0} right={0} bottom={0}>
        {children}
      </Box>
    </Box>
  );
};
