import { ResizeObserver as polyfill } from '@juggle/resize-observer';
import { Link } from "gatsby";
import React from "react";
import { GatsbyImage } from 'gatsby-plugin-image';
import { animated, config, useSpring } from 'react-spring';
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { useInViewSpring } from "../../hooks/useInViewSpring";
import { useIsMobile } from "../../hooks/useIsMobile";
import { color } from "../../theme/color";
import { grid } from "../../theme/theme";
import { themeTransition } from "../../theme/themeTransition";
import { addURLPrefix } from "../../utils/addURLPrefix";
import { extractWithLocale } from "../../utils/extractWithLocale";
import { Sections_SanityRelatedStuffSection, Sections_SanityRelatedStuffSection_items } from "../../__generated__/graphql";
import { AspectBox } from "../common/AspectBox";
import { Box } from "../common/Box";
import { Grid } from "../common/Grid";
import { Text } from "../common/Text";
import { useLocale } from "../CurrentLocale";
import PlayTeaserOverlay from "../PlayTeaserOverlay";
import TrackLength from "../TrackLength";
import { AnimatedBox, springAnimation } from "./grid/gridHelpers";
import { getUrlPrefix } from "./LatestPostsSection";
import { ReactComponent as LinkArrow } from '../../images/icons/link-arrow.svg';
import { InnerGrid } from '../common/InnerGrid';

export type RelatedStuffSectionProps = {
  section: Sections_SanityRelatedStuffSection;
};

export const RelatedStuffSection: React.FC<RelatedStuffSectionProps> = ({ section }) => {
  const locale = useLocale();

  const items = section.items;
  const label = extractWithLocale(section.label, locale);

  return (
    <Box gridColumn="1 / -1" backgroundColor={color.black} color={color.white} mb={5}>
      <InnerGrid gridTemplateColumns={grid}>
        <Box gridColumn="2 / -2">
          {label && <Box px={[4, 4, 2]}>
            <Text
              pt={5}
              fontFamily='Quarto A, Quarto, Quarto B'
              fontSize={[5, 6]}
              fontWeight={900}
              color={color.white}>
              {label}
            </Text>
          </Box>}

          <Grid gridTemplateColumns='1fr 1fr' pb={[5, 6]} pt={[5, label ? 5 : 6]}>
            {items?.map((item, index) => {
              return item && <StuffItem
                item={item}
                index={index}
                key={index}
              />
            })}
          </Grid>
        </Box>
      </InnerGrid >
    </Box >
  );
}

export type StuffItemProps = {
  item: Sections_SanityRelatedStuffSection_items;
  index: number;
};

const imageScale = (x: number, y: number, s: number) => {
  const scale = s < 1 ? 1 : s;
  return `translate(${y}px, ${x}px) scale(${scale})`;
};

const StuffItem: React.FC<StuffItemProps> = ({
  item,
  index,
}) => {
  const isMobile = useIsMobile();
  const locale = useLocale();

  const [ref, springProps] = useInViewSpring({
    spring: springAnimation,
    index,
  });
  const [measureRef] = useMeasure({ scroll: true, debounce: 100, polyfill });

  const [imageStyle] = useSpring(() => ({
    xys: [0, 0, 1],
    config: config.stiff,
  }));

  const heading = extractWithLocale(item.heading, locale);
  const teaserHeading = extractWithLocale(item.teaser?.teaserHeading, locale);
  const title = teaserHeading || heading || '';
  const slug = extractWithLocale(item.slug, locale)?.current;
  const fluidImage = item.teaser?.teaserImage?.asset?.gatsbyImageData || item.image?.asset?.gatsbyImageData;
  const imageAltText = extractWithLocale(item.imageAltText, locale) || '';

  const categoryName = getCategoryName(item);

  const url = addURLPrefix(slug, getUrlPrefix(item.__typename), locale);

  const isPlay = item.__typename === 'SanityPlay';
  const isArticle = item.__typename === 'SanityArticle';
  const stuffLength = () => {
    if (isPlay) {
      return item.embeddedVideo?.videoLength || item.embeddedAudio?.length || null;
    } else if (isArticle) {
      return item.embeddedVideo?.videoLength || null;
    } else {
      return null;
    }
  };
  const teaserType = () => {
    if (isPlay) {
      return item.embeddedAudio?.url ? 'audio' : 'video';
    } else if (isArticle && item.embeddedVideo?.videoLength) {
      return 'video';
    } else {
      return null;
    }
  };

  return <AnimatedBox
    style={springProps}
    gridColumn={['span 4', 'span 4', 'auto', 'auto']}
    gridRow={'auto'}
    zIndex={1}
    mb={[4, 4]}
    mt={[0]}
    mr={[4, 4, 2]}
    ml={[4, 4, 2]}
    pr={2}
    pl={2}
  >
    <div ref={ref}>
      <StuffLink to={url}>
        <Grid gridTemplateColumns={'1fr'}>

          <PreviewBox mb={0} style={{ overflow: 'hidden' }} ref={measureRef}>
            {stuffLength() && <TrackLength length={stuffLength() || ''} darkVersion={true} />}
            <Box>
              {fluidImage ? (
                <AnimatedImg
                  image={{ ...fluidImage, aspectRatio: 5 / 3 }}
                  style={{ transform: imageStyle.xys.interpolate(imageScale as any) }}
                  alt={imageAltText}
                />
              ) : (
                <AspectBox aspectRatio={360 / 600} />
              )}
            </Box>
            {(isPlay || isArticle) && teaserType() && <PlayTeaserOverlay type={teaserType()} />}
          </PreviewBox>

          <Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {categoryName && (
                <Text
                  fontSize={[3, 26]}
                  mt={[2]}
                  fontFamily="Handsome"
                  style={{ display: 'inline-flex' }}
                  color={color.white}
                >
                  {categoryName}
                </Text>
              )}
            </Box>
            <Box mt={0} ml={-1} maxWidth="95%">
              <StuffTitle
                display="inline"
                fontSize={[4, 4]}
                fontWeight={500}
                lineHeight={1}
                p={1}
                pt={0}
                letterSpacing="-0.02em"
                style={{
                  textDecoration: 'none',
                }}
                color={color.white}
              >
                {title}
              </StuffTitle>
            </Box>

            {isMobile && <Arrow>
              <LinkArrow style={{ maxWidth: '36px', maxHeight: '19px' }} />
            </Arrow>}
          </Box>

        </Grid>
      </StuffLink>
    </div>
  </AnimatedBox>;
}

const getCategoryName = (item: Sections_SanityRelatedStuffSection_items) => {
  switch (item.__typename) {
    case 'SanityArticle':
      return (item.categories && item.categories.title) || 'Stuff';
    case 'SanityCase':
      return 'Case';
    case 'SanityPlay':
    default:
      return (item.playCategoires && item.playCategoires[0]?.title) || 'Esatto Play';
  }
}

const StuffTitle = styled(Text)`
  ${themeTransition('background-color', 0.3)}
  position: relative;
`;

const StuffLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover ${StuffTitle} {
    background-color: #ddd;
  }
`;

const PreviewBox = styled(AnimatedBox)`
  position: relative;
  color: black;
`;

const AnimatedImg = animated(GatsbyImage);

const Arrow = styled.div`
  filter: invert(1);
  margin-top: 0.75rem;
  ${themeTransition('transform')};
`;