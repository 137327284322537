import React from "react";
import styled from "styled-components";
import { color } from "../../theme/color";
import { grid } from "../../theme/theme";
import { extractWithLocale } from "../../utils/extractWithLocale";
import { Sections_SanityFocusAreasSection } from "../../__generated__/graphql";
import { Box } from "../common/Box";
import { InnerGrid } from "../common/InnerGrid";
import { Text } from "../common/Text";
import { useLocale } from "../CurrentLocale";

export type FocusAreasSectionProps = {
  section: Sections_SanityFocusAreasSection;
};

export const FocusAreasSection: React.FC<FocusAreasSectionProps> = ({ section }) => {
  const locale = useLocale();

  const items = section.items || [];
  const label = extractWithLocale(section.label, locale);

  return <>
    <Box gridColumn="1 / -1" backgroundColor={color.pink} mt={5}>
      <InnerGrid gridTemplateColumns={grid}>
        {label && <Box gridColumn="2 / -2" height={0}>
          <TitleBox>
            <Text fontSize={[3, 38]} fontWeight={800} fontFamily='Quarto A, Quarto, Quarto B'>
              {label}
            </Text>
          </TitleBox>
        </Box>}

        <Box gridColumn="2 / -2" py={[4, 40]} px={[4, 4, 0]}>
          {items.map((item, index) => {
            const label = extractWithLocale(item, locale);

            return label && <AreaItem key={index}>
              {label}
            </AreaItem>;
          })}
        </Box>
      </InnerGrid>
    </Box>
  </>;
}

const TitleBox = styled(Box)`
  background-color: ${color.pink};
  position: relative;
  top: -40px;
  width: fit-content;
  padding: 16px 36px;
`;

const AreaItem = styled(Box)`
  float: left;
  padding: 20px 20px;
  color: ${color.black};
  margin: 4px;
  font-size: 30px;
  line-height: 40px;
  word-break: break-word;
  font-family: "Rubik";
  font-weight: 300;

  &:not(:last-child):after {
    content: '/';
    font-size: 40px;
    padding-left: 40px;
    vertical-align: middle;
  }

  @media screen and (max-width: 600px) {
    padding: 10px 10px;
    font-size: 16px;
    line-height: 20px;

    &:not(:last-child):after {
      font-size: 20px;
      padding-left: 20px;
    }
  }
`;