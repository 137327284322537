import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import ErrorMessage from './ErrorMessage';
import { Text } from './Text';
import { Translations } from '../../i18n/translations';

import checkmark from '../../images/icons/checkmark.svg';

type CheckboxProps = React.HTMLProps<HTMLInputElement> & {
  text?: ReactNode;
  touched?: boolean;
  error?: string;
  labelSize?: number[];
};

const Checkbox: FC<CheckboxProps> = ({ ref, as, text, touched, error, labelSize, ...props }) => (
  <>
    <Wrapper ref={ref as any} htmlFor={props.name}>
      <CheckboxWrapper>
        <CheckboxInput id={props.name} {...props} />
        <Checkmark src={checkmark} checked={props.checked} />
      </CheckboxWrapper>
      <Label fontSize={labelSize || [14, 18]} fontWeight="300">
        {text}
      </Label>
    </Wrapper>
    {touched && error && <ErrorMessage error={error as keyof Translations} />}
  </>
);

const Wrapper = styled.label`
  display: flex;
`;

const Label = styled(Text)`
  margin-left: 10px;
`;

const CheckboxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border: 1px solid #555;
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' as string })`
  display: none;
`;

const Checkmark = styled.img<{ checked?: boolean }>`
  opacity: ${props => Number(props.checked)};
  width: 100%;
  height: 80%;
`;

export default Checkbox;
