import { useWindowSize } from './useWindowSize';

const widths = {
  mobile: 640,
  desktop: 1024,
};

export const useIsMobile = () => {
  const windowClass = useWindowClass();

  return windowClass === 'mobile';
};

export const useWindowClass = (): 'mobile' | 'tablet' | 'desktop' => {
  const { width } = useWindowSize();

  if (width <= widths.mobile) {
    return 'mobile';
  } else if (width >= widths.desktop) {
    return 'desktop';
  } else {
    return 'tablet';
  }
};
