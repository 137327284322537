import React from 'react';
import { bodyPadding } from '../../theme/theme';
import {
  Sections_SanityGridSection,
  Sections_SanityGridSection_items,
} from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { ImageGridItemSection } from './grid/ImageGridItemSection';
import { QuoteSection } from './grid/QuoteSection';
import { TextAndHeadingSection } from './grid/TextAndHeadingSection';
import { ImageAndTextSection } from './grid/ImageAndTextSection';
import { LineSection } from './grid/LineSection';

export type GridSectionProps = {
  section: Sections_SanityGridSection;
};

export const GridSection: React.FC<GridSectionProps> = ({ section }) => {
  const va = section.verticalAlignment;
  const alignItems = va === 'bottom' ? 'end' : va === 'top' ? 'start' : 'center';

  return (
    <Box as="section" mt={4} bg="transparent" gridColumn="2 / -2" px={bodyPadding} pb={6}>
      <InnerGrid columns={2} gridGap={3} alignItems={alignItems}>
        {section.items?.map((item, index) => (
          <GridItem key={item?._key ?? index} item={item} index={index} />
        ))}
      </InnerGrid>
    </Box>
  );
};

const GridItem: React.FC<{ item: Sections_SanityGridSection_items | null; index: number }> = ({
  item,
  index,
}) => {
  if (!item) return null;

  switch (item.__typename) {
    case 'SanityImageGridImage':
      return <ImageGridItemSection section={item} index={index} />;
    case 'SanityImageAndTextSection':
      return <ImageAndTextSection section={item} index={index} />;
    case 'SanityQuoteSection':
      return <QuoteSection section={item} index={index} />;
    case 'SanityTextAndHeadingSection':
      return <TextAndHeadingSection section={item} index={index} />;
    case 'SanityLineSection':
      return <LineSection section={item} />;
  }

  return null;
};
