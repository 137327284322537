export type GetGridColumnsFromAlignmentOptions = {
  singleColumnOnMobile?: boolean;
};

export const getGridColumnsFromAlignment = (
  alignment: string | null,
  options: GetGridColumnsFromAlignmentOptions = {}
): string | string[] => {
  let current = '';

  switch (alignment) {
    case 'auto':
      current = 'auto';
      break;
    case 'left':
      current = '1 / span 1';
      break;
    case 'right':
      current = '2 / span 1';
      break;
    case 'full':
      current = '1 / -1';
      break;
    default:
      current = 'auto';
  }

  if (options.singleColumnOnMobile) {
    return ['1 / -1', current];
  }

  return current;
};
