import React from 'react';
import { Sections } from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { AllEmployeesListSection } from './AllEmployeesListSection';
import { ArticleListSection } from './ArticleListSection';
import { CaseListSection } from './CaseListSection';
import { GridSection } from './GridSection';
import { InfoSnippetSection } from './InfoSnippetSection';
import { LinkGroupSection } from './LinkGroupSection';
import { MapSection } from './MapSection';
import { MultiColumnSection } from './MultiColumnSection';
import { OfficeListSection } from './OfficeListSection';
import { OpportunitiesListSection } from './OpportunitiesListSection';
import { DiamondSection } from './diamondSection';
import { LogoSection } from './LogoSection';
import { TextSection } from './textSection';
import { TableSection } from './TableSection';
import { EmbeddedVideoSection } from './EmbeddedVideoSection';
import { NetlifyFormSection } from './NetlifyFormSection';
import { TalksListSection } from './TalksListSection';
import { CookiesSettingsSection } from './CookiesSettingsSection';
import { PlayListSection } from './PlayListSection';
import { LatestPostsSection } from './LatestPostsSection';
import { FocusAreasSection } from './FocusAreasSection';
import { RelatedStuffSection } from './RelatedStuffSection';
import FormSection from './FormSection';

export type Section = {
  raw?: any | null | undefined;
} & Sections;

export type SectionRendererProps = {
  sections: Section[];
};

export const SectionRenderer: React.FC<SectionRendererProps> = ({ sections }) => {
  return (
    <>
      {sections.map(section => {
        if (!('_key' in section)) {
          return null;
        }
        const key = `${section.__typename}__${section._key}`;

        switch (section.__typename) {
          case 'SanityGridSection':
            return <GridSection key={key} section={section} />;
          case 'SanityMultiColumnSection':
            return <MultiColumnSection key={key} section={section} />;
          case 'SanityOfficeListSection':
            return <OfficeListSection key={key} section={section} />;
          case 'SanityAllEmployeesListSection':
            return <AllEmployeesListSection key={key} section={section} />;
          case 'SanityCaseListSection':
            return <CaseListSection key={key} section={section} />;
          case 'SanityArticleListSection':
            return <ArticleListSection key={key} section={section} />;
          case 'SanityInfoSnippetSection':
            return <InfoSnippetSection key={key} section={section} />;
          case 'SanityMapSection':
            return <MapSection key={key} section={section} />;
          case 'SanityLinkGroupSection':
            return <LinkGroupSection key={key} section={section} />;
          case 'SanityOpportunitiesListSection':
            return <OpportunitiesListSection key={key} section={section} />;
          case 'SanityDiamondSection':
            return <DiamondSection key={key} section={section} />;
          case 'SanityLogoSection':
            return <LogoSection key={key} section={section} />;
          case 'SanityTextSection':
            return <TextSection key={key} section={section} />;
          case 'SanityTableSection':
            return <TableSection key={key} section={section} />;
          case 'SanityEmbeddedVideoSection':
            return <EmbeddedVideoSection key={key} section={section} space={true} />;
          case 'SanityNetlifyFormSection':
            return <NetlifyFormSection key={key} section={section} />;
          case 'SanityTalksListSection':
            return <TalksListSection key={key} section={section} />;
          case 'SanityCookiesSettingsSection':
            return <CookiesSettingsSection key={key} section={section} />;
          case 'SanityPlayListSection':
            return <PlayListSection key={key} section={section} />;
          case 'SanityLatestPostsSection':
            return <LatestPostsSection key={key} section={section} />;
          case 'SanityFocusAreasSection':
            return <FocusAreasSection key={key} section={section} />;
          case 'SanityRelatedStuffSection':
            return <RelatedStuffSection key={key} section={section} />;
          case 'SanityFormSection':
            return <FormSection key={key} section={section} />;
          default:
            return (
              <Box key={key} gridColumn="2 / -2">
                Section type <code>{section.__typename}</code> not supported
              </Box>
            );
        }
      })}
    </>
  );
};
