import React, { useState } from 'react';
import styled from 'styled-components';
import { bodyPadding } from '../../theme/theme';
import { Box } from '../common/Box';
import { Sections_SanityNetlifyFormSection } from '../../__generated__/graphql';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { useLocale } from '../CurrentLocale';
import { H2 } from '../common/Heading';
import { NetlifyForm } from '../NetlifyForm';

type NetlifyFormSectionProps = {
  section: Sections_SanityNetlifyFormSection
}

export const NetlifyFormSection: React.FC<NetlifyFormSectionProps> = ({ section }) => {
  const locale = useLocale();

  const formName = (section.formName || 'Netlify form section').replace(' ', '_');
  const heading = extractWithLocale(section.heading, locale);
  const description = extractWithLocale(section.description, locale);
  const gdprDescription = extractWithLocale(section.gdprDescription, locale);
  const submitForm = extractWithLocale(section.submitFormLabel, locale);
  const formEmailPlaceholder = extractWithLocale(section.formEmailPlaceholder, locale);

  const hasEmail = section.hasEmail || false;

  return <Box as="section" gridColumn="2 / -2" my={3} padding={bodyPadding} style={{ paddingTop: '10px', borderTop: 'solid 2px #000', marginBottom: 0 }}>
    <FormHeading width={['100%', '100%', '66%']}>
      <H2 fontSize={[5, 6]} style={{ margin: 0, lineHeight: '48px' }}>{heading}</H2>
      <StyledP>{description}</StyledP>
    </FormHeading>

    <Box width={['100%', '66%', '33%']}>
      <NetlifyForm
        formName={formName}
        hasEmail={hasEmail}
        emailPlaceholder={formEmailPlaceholder}
        gdprDescription={gdprDescription}
        sendButtonText={submitForm}
      />
    </Box>
  </Box>;
}

const FormHeading = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const StyledP = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 30px;
`;