import React from 'react';
import { useInViewSpring } from '../../../hooks/useInViewSpring';
import { getGridColumnsFromAlignment } from '../../../utils/getGridColumnsFromAlignment';
import { GridItems_SanityQuoteSection } from '../../../__generated__/graphql';
import { Box } from '../../common/Box';
import { Text } from '../../common/Text';
import { AnimatedBox, springAnimationWithDelay2 } from './gridHelpers';
import { extractWithLocale } from '../../../utils/extractWithLocale';
import { useLocale } from '../../CurrentLocale';

export type QuoteSectionProps = {
  section: GridItems_SanityQuoteSection;
  index: number;
};

const BlockQuote = Box.withComponent('blockquote');

export const QuoteSection: React.FC<QuoteSectionProps> = ({ section, index }) => {
  const locale = useLocale();
  const [ref, springProps] = useInViewSpring({ spring: springAnimationWithDelay2, index });
  const alignment = section.alignment;

  const quote = extractWithLocale(section.quote, locale);
  const author = extractWithLocale(section.author, locale);

  return (
    <AnimatedBox
      gridColumn={getGridColumnsFromAlignment(alignment, { singleColumnOnMobile: true })}
      as="section"
      style={springProps}
      alignSelf={'start'}
      pr={5}
    >
      <BlockQuote ref={ref} ml={0} mt={0} px={0}>
        <Text as="p" fontWeight={500} fontSize={[3, 4]} mb={3} mt={0} lineHeight={1.2}>
          {quote}
        </Text>
        <Text as="footer" fontWeight={300} fontSize={'18px'}>
          — {author}
        </Text>
      </BlockQuote>
    </AnimatedBox>
  );
};
