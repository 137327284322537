import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { extractWithLocale } from '../utils/extractWithLocale';
import { getLocalizedLink } from '../utils/getLocalizedLink';
import { Sections_SanityInfoSnippetSection_items_SanityInfoSnippetLinkList_linkListLinks } from '../__generated__/graphql';
import { Box } from './common/Box';
import { LinkWithArrrow } from './common/LinkWithArrow';
import { useLocale } from './CurrentLocale';

export type InfoSnippetLinkListProps = {
  links:
    | (Sections_SanityInfoSnippetSection_items_SanityInfoSnippetLinkList_linkListLinks | null)[]
    | null;
};

export const InfoSnippetLinkList: React.FC<InfoSnippetLinkListProps> = ({ links }) => {
  const locale = useLocale();

  return (
    <div>
      {links?.map((item, index) => {
        if (!item) return null;
        const title =
          item.__typename === 'SanityPage'
            ? extractWithLocale(item.name, locale) || ''
            : extractWithLocale(item.title, locale);

        const slug = getLocalizedLink(item, locale);

        return (
          <Box display="flex" key={index + slug}>
            <LinkWithArrrow href={slug}> {title}</LinkWithArrrow>
          </Box>
        );
      })}
    </div>
  );
};

const StyledLink = styled(Link)``;
