import React from 'react';
import { bodyPadding } from '../../theme/theme';
import { Box } from '../common/Box';
import { H1 } from '../common/Heading';
import { InnerGrid } from '../common/InnerGrid';
import { Text } from '../common/Text';

export type TalksTopSectionProps = {
  heading: string | null;
  preamble: string | null;
};

export const TalksTopSection: React.FC<TalksTopSectionProps> = ({ heading, preamble }) => {
  return (
    <InnerGrid px={bodyPadding} mb={[4, 5]}>
      <Text fontSize={[3, 4]} ml={'2px'} fontFamily="Handsome" style={{ display: 'inline-flex' }}>
        {preamble || 'Talks'}
      </Text>
      <Box gridColumn={['1 / -1', '1 / -2']} pr={[0, 4]}>
        <H1 fontSize={[5, 6]} mb={0}>
          {heading}
        </H1>
      </Box>
    </InnerGrid>
  );
};
