import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useCategoryMenu = (
  path: string
): [string | null, Dispatch<SetStateAction<string | null>>] => {
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    const pathname = window.location.pathname;
    const category = pathname.replace(`/${path}`, '').slice(1);
    const normalized = category.replace('-', ' ');

    if (normalized) {
      const param = normalized;
      setSelected(param);
    }
  }, [path]);

  return [selected, setSelected];
};
