import React from 'react';
import { useInViewSpring } from '../../../hooks/useInViewSpring';
import { extractWithLocale } from '../../../utils/extractWithLocale';
import { getGridColumnsFromAlignment } from '../../../utils/getGridColumnsFromAlignment';
import { GridItems_SanityTextAndHeadingSection } from '../../../__generated__/graphql';
import { Text } from '../../common/Text';
import { useLocale } from '../../CurrentLocale';
import { AnimatedBox, springAnimationWithDelay2 } from './gridHelpers';
import { MiniBlockContentRenderer } from '../../common/MiniBlockContentRenderer';

export type TextAndHeadingSectionProps = {
  section: GridItems_SanityTextAndHeadingSection;
  index: number;
};

export const TextAndHeadingSection: React.FC<TextAndHeadingSectionProps> = ({ section, index }) => {
  const locale = useLocale();
  const [ref, springProps] = useInViewSpring({ spring: springAnimationWithDelay2, index });
  const heading = extractWithLocale(section.heading, locale);
  const body = extractWithLocale(section.body, locale);
  const sectionText = extractWithLocale(section.sectionText, locale);

  const lines = body?.split('\n') ?? [];

  return (
    <AnimatedBox
      as="section"
      style={springProps}
      gridColumn={getGridColumnsFromAlignment(section.alignment, { singleColumnOnMobile: true })}
      pr={5}
    >
      <div ref={ref}>
        <Text as="p" fontWeight={500} fontSize={[3, 4]} mb={3} mt={0} lineHeight={1.2}>
          {heading}
        </Text>

        {!sectionText && lines.map(line => {
          if (line === '') return null;

          return (
            <Text key={line} as="p" fontWeight={300} fontSize={3}>
              {line}
            </Text>
          );
        })}

        {sectionText && (
          <MiniBlockContentRenderer body={sectionText} />
        )}
      </div>
    </AnimatedBox>
  );
};
