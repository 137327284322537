import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../images/icons/link-arrow-orange.svg';
import { themeTransition } from '../../theme/themeTransition';
import { Box } from './Box';
import { color } from '../../theme/color';
import { Text, TextProps } from './Text';

type LinkWithArrrowProps = TextProps & {
  href?: string;
  target?: string | undefined;
  small?: boolean;
  onClick: () => void;
};

export const LinkWithArrrow: React.FC<LinkWithArrrowProps> = ({ children, ...props }) => (
  <Wrapper overflowX="hidden" py="1em" position="relative" pr="3em">
    <StyledLink {...(props as any)}>
      {children}
      <ArrowAnchor small={props.small || false} />
    </StyledLink>
  </Wrapper>
);

const ArrowAnchor = styled(ArrowIcon) <{ small: boolean }>`
  transition: ${themeTransition(['color', 'transform'], 0.3)};
  width: ${props => props.small ? '20px' : '30px'};
  stroke: #000;
  fill: none;
  transform: translateX(10px);
`;

const StyledLink = styled(Text.withComponent('a')) <{ small: boolean }>`
  display: flex;
  align-items: center;
  &:hover {
    color: ${color.blue};
    svg {
      stroke: ${color.blue};
      transform: translateX(${props => props.small ? '20px' : '30px'});
    }
  }
`;

const Wrapper = styled(Box)`
  /* border-top: 1px solid ${props => props.theme.colors.text}; */
`;

LinkWithArrrow.defaultProps = {
  ...Text.defaultProps,
  fontWeight: 300,
  fontSize: [3, 3, 4],
};
