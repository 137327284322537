import React, { FC } from 'react';
import styled from 'styled-components';
import { themes } from '../theme/theme';
import { ArticleListQuery_categories } from '../__generated__/graphql';
import { Box } from './common/Box';
import { ThemeContextType, useCurrentThemeName } from './ThemeChanger';

export type ArticleListSectionProps = {
  categories: ArticleListQuery_categories['nodes'];
  selected: string | null;
  onChange: (selected: string | null) => void;
  onCategoryName?: (name: string | null) => string | null;
  allLabel: string | null;
  fontSize: number | string | null;
};

export const CategoriesMenu: FC<ArticleListSectionProps> = ({
  categories,
  selected,
  onChange,
  onCategoryName,
  allLabel,
  fontSize,
}) => {
  const currentTheme = useCurrentThemeName();

  return (
    <Wrapper theme={currentTheme} mb={[3, 4]}>
      <CategoryList theme={currentTheme} style={{ fontSize: fontSize || 16 }}>
        <CategoryListItem active={!selected} theme={currentTheme} onClick={() => onChange(null)}>
          {allLabel || 'Alla'}
        </CategoryListItem>
        {categories.map(({ title }) => (
          <CategoryListItem
            key={title}
            active={title?.toLowerCase() === selected}
            theme={currentTheme}
            onClick={() => onChange(title && title.toLowerCase())}
          >
            {onCategoryName ? onCategoryName(title) : title}
          </CategoryListItem>
        ))}
      </CategoryList>
    </Wrapper>
  );
};

const CategoryList = styled(Box)<{ theme: ThemeContextType['current'] }>`
  height: 100%;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: nowrap;
  }
`;

const CategoryListItem = styled.li<{ active?: boolean; theme: ThemeContextType['current'] }>`
  display: inline-flex;
  margin-right: 90px;
  padding-bottom: 15px;
  border-bottom: 2px solid white;
  color: ${props => themes[props.theme].colors.text};
  border-color: ${props =>
    (props.active && props.theme === 'light') || (!props.active && props.theme === 'dark')
      ? 'black'
      : 'white'};
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    margin-right: 30px;
  }
`;

const Wrapper = styled(Box)`
  max-width: calc(100vw - 2rem);
  grid-column: 1 / -1;
  overflow-x: auto;
  border-bottom: 1px solid ${props => themes[props.theme].colors.text};

  ::-webkit-scrollbar {
    display: none;
  }
`;
