import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Box } from './Box';
import { TextProps } from './Text';
import { H2 } from './Heading';

export const LineHeading: React.FC<TextProps> = props => {
  const { colors } = useContext(ThemeContext);

  return (
    <Box mt={[2, 4]} mb={[2, 3]} py={3} borderTop={`solid 2px ${props.color || colors.text}`}>
      <H2
        fontWeight={props.fontWeight || 900}
        fontSize={[3, 4, 5]}
        fontFamily="Quarto A, Quarto, Quarto B"
        {...(props as any)}
      />
    </Box>
  );
};
