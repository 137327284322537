import { useFormik } from 'formik';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import * as yup from 'yup';

import { useTranslation } from '../i18n/useTranslation';
import { encodeUrlParams } from '../utils/form';
import { Box } from './common/Box';
import { ButtonHover } from './common/ButtonHover';
import Checkbox from './common/Checkbox';
import { StyledForm, FormItem, StyledInput } from './common/Form';
import { MiniBlockContentRenderer } from './common/MiniBlockContentRenderer';

type NetlifyFormProps = {
  formName: string;
  hasEmail: boolean;
  emailPlaceholder?: string;
  gdprDescription: string;
  sendButtonText?: string;
};

const validationSchema = (emailRequired: boolean) => {
  let validation = {
    gdpr: yup.bool().oneOf([true], 'fieldRequired'),
  };

  if (emailRequired) {
    validation.email = yup.string().email('incorrectEmail').required('fieldRequired');
  }

  return yup.object(validation);
};

export const NetlifyForm: React.FC<NetlifyFormProps> = ({
  formName,
  hasEmail,
  gdprDescription,
  sendButtonText,
  emailPlaceholder,
}) => {
  const t = useTranslation();

  const [success, setSuccess] = useState(false);

  const showSuccessMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const form = useFormik({
    initialValues: {
      email: '',
      gdpr: false,
    },
    validationSchema: validationSchema(hasEmail),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (formName === 'Talks-submitted-form') {
        window.dataLayer.push({ event: 'interactionsTalksSignUp', pagePath: window.location.href });
      }

      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encodeUrlParams({ 'form-name': formName, ...values }),
        });
        setSubmitting(false);
        resetForm();
        showSuccessMessage();
      } catch (error) {
        alert(error);
      }
    },
  });

  return (
    <StyledForm
      name={formName}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={form.handleSubmit}
    >
      <input type="hidden" name="form-name" value={formName} />
      {hasEmail && (
        <FormItem>
          <StyledInput
            type="email"
            name="email"
            placeholder={emailPlaceholder || 'Email'}
            value={form.values.email}
            error={form.errors.email}
            touched={form.touched.email}
            onChange={form.handleChange}
            style={{ maxHeight: 52 }}
          />
        </FormItem>
      )}

      <Box pb={2}>
        <SendButton center={success}>
          <SendButtonText show={!success} center={false}>
            {sendButtonText}
          </SendButtonText>
          <SendButtonText show={success} center={true}>
            {t('thankYou')}!
          </SendButtonText>
        </SendButton>
      </Box>

      <FormItem>
        <Checkbox
          name="gdpr"
          checked={form.values.gdpr}
          error={form.errors.gdpr}
          touched={form.touched.gdpr}
          onChange={form.handleChange}
          text={<MiniBlockContentRenderer body={gdprDescription} />}
        />
      </FormItem>
    </StyledForm>
  );
};

const SendButton = styled(ButtonHover)<{ center: boolean }>`
  width: 100%;
  max-height: 52px;
  padding: 1rem;

  &::after {
    ${props =>
      props.center &&
      css`
        transform: scale(0);
      `}
  }
`;

const SendButtonText = styled(Box)<{ show: boolean; center: boolean }>`
  position: ${props => (props.center ? 'absolute' : 'relative')};
  display: flex;
  width: calc(100% - 2rem);
  opacity: ${props => (props.show ? 1 : 0)};
  transition: 0.3s;

  ${props =>
    !props.center &&
    css`
      padding-left: 1rem;
      text-align: left;
      ${props.show &&
      css`
        transition-delay: 0.2s;
      `}
    `}

  ${props =>
    props.center &&
    css`
      text-align: center;
      justify-content: center;
      ${props.show &&
      css`
        transition-delay: 0.2s;
      `}
    `}
`;
