import { animated } from 'react-spring';
import { UseInViewSpringProps } from '../../../hooks/useInViewSpring';
import { Box } from '../../common/Box';

export const springAnimationWithDelay = (
  numbers: number = 2,
  delay: number = 250
): NonNullable<UseInViewSpringProps['spring']> => (inView, index) => {
  const itemDelay = index % numbers === 0 ? 0 : (index % numbers) * delay;

  return {
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0vh)' : 'translateY(20vh)',
    delay: inView ? itemDelay : 0,
  };
};

export const springAnimationWithDelay2 = springAnimationWithDelay(2);
export const springAnimationWithDelay3 = springAnimationWithDelay(3, 125);

export const springAnimation: NonNullable<UseInViewSpringProps['spring']> = (inView, index) => ({
  opacity: inView ? 1 : 0,
  transform: inView ? 'translateY(0vh)' : 'translateY(20vh)',
});

export const AnimatedBox = animated(Box);
