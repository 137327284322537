import React, { useContext } from 'react';
import { Sections_SanityInfoSnippetSection_items_SanityInfoSnippet_person } from '../../__generated__/graphql';
import { Text } from './Text';
import { useLocale } from '../CurrentLocale';
import { MiniBlockContentRenderer } from './MiniBlockContentRenderer';
import Obfuscate from "react-obfuscate";
import { ThemeContext } from 'styled-components';
import { Box } from './Box';
import { LinkWithArrrow } from './LinkWithArrow';
import { useTranslation } from '../../i18n/useTranslation';
import { Translations } from '../../i18n/translations';

export type PersonFieldProps = {
  item: Sections_SanityInfoSnippetSection_items_SanityInfoSnippet_person;
  raw: any;
  columnsAmount: string | null;
};

export const PersonField: React.FC<PersonFieldProps> = ({ item, raw, columnsAmount }) => {
  const locale = useLocale();
  const t = useTranslation();
  const { colors } = useContext(ThemeContext);

  let fontSize = [2, 3];

  if (columnsAmount === '4') {
    fontSize = [2, 2];
  }

  if (!item) return null;
  return (
    <Box>
      {raw.text && <MiniBlockContentRenderer body={raw.text[locale]} />}
      {item.displayInfo?.includes('name') && (
        <Text fontSize={fontSize} fontWeight={700}>
          {item.person?.person?.firstName} {item.person?.person?.lastName}
        </Text>
      )}
      {item.displayInfo?.includes('role') && (
        <Text fontWeight={300} fontSize={fontSize}>{item.person?.person?.title ?? ''}</Text>
      )}
      {item.displayInfo?.includes('email') && (
        <Obfuscate style={{ color: colors.text, fontWeight: 300 }} email={item.person?.person?.email} />
      )}
      {item.displayInfo?.includes('phone') && (
        <Text display="block" fontSize={fontSize} fontWeight={300} color="text" as="a" href={`tel:${item.person?.person?.phone}`}>
          {item.person?.person?.phone}
        </Text>
      )}
    </Box>
  );
};

export const PersonFieldLink: React.FC<{
  link: string;
  translate: (key: keyof Translations) => string
}> = ({ link, translate }) => {
  return (
    <LinkWithArrrow href={link} onClick={() => { }} style={{ textDecoration: 'none' }} py={0} small={true}>
      <Text fontWeight={500} fontSize={16}>
        {translate('visitMyProfile')}
      </Text>
    </LinkWithArrrow>
  );
}