import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useInViewSpring } from '../../hooks/useInViewSpring';
import { useIsMobile } from '../../hooks/useIsMobile';

import { bodyPadding } from '../../theme/theme';
import { getMapLink } from '../../utils/getMapLink';
import {
  OfficeListQuery,
  OfficeListQuery_offices_nodes,
  Sections_SanityOfficeListSection,
} from '../../__generated__/graphql';
import { A } from '../common/A';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { Text } from '../common/Text';
import { AnimatedBox, springAnimation, springAnimationWithDelay3 } from './grid/gridHelpers';

export type OfficeListSectionProps = {
  section: Sections_SanityOfficeListSection;
};

export const OfficeListSection: React.FC<OfficeListSectionProps> = ({ section }) => {
  const data = useStaticQuery<OfficeListQuery>(query);
  const offices = data.offices.nodes;

  return (
    <Box as="section" px={bodyPadding} mt={0} pb={4} bg="bg" gridColumn="2 / -2">
      <InnerGrid as="section" mb={4} columns={[1, 3]} gridGap={3}>
        {offices.map((office, index) => {
          return <OfficeItem office={office} key={office.id} index={index} />;
        })}
      </InnerGrid>
    </Box>
  );
};

const OfficeItem: React.FC<{ office: OfficeListQuery_offices_nodes; index: number }> = ({
  office,
  index,
}) => {
  const { location, id, contact } = office;
  const isMobile = useIsMobile();

  const [ref, springProps] = useInViewSpring({
    spring: isMobile ? springAnimation : springAnimationWithDelay3,
    inViewOptions: { triggerOnce: true },
    index,
  });

  if (!contact || !location || !id) return null;

  return (
    <AnimatedBox style={springProps} key={id}>
      <div ref={ref}>
        <Box display={['none', 'block']}>
          <GatsbyImage
            image={contact?.image?.asset?.gatsbyImageData}
            alt={contact.image?.asset?.label}
          />
        </Box>
        <Box mt={[0, 3]}>
          <Text fontWeight={500} fontFamily="Quarto A, Quarto, Quarto B" fontSize={5} m={0}>
            {contact.firstName}
          </Text>
          <Text>{location.address}</Text>
          <Text>
            {location.postalCode} {location.city}
          </Text>
          <Text>{location.country}</Text>
          <A href={`tel:${contact.phone}`}>
            <Text fontWeight={400}>{contact.phone}</Text>
          </A>
          {location.coordinates && (
            <A href={getMapLink(location.coordinates)} target="_blank">
              <Text fontWeight={400}>Google maps</Text>
            </A>
          )}
        </Box>
      </div>
    </AnimatedBox>
  );
};

const query = graphql`
  query OfficeListQuery {
    offices: allSanityOffice {
      nodes {
        ...OfficeListOffice
      }
    }
  }

  fragment OfficeListOffice on SanityOffice {
    id
    contact {
      firstName
      title
      phone
      email
      image {
        asset {
          gatsbyImageData(width: 300, height: 300)
          label
        }
      }
    }
    location {
      address
      postalCode
      city
      country
      coordinates {
        lat
        lng
        alt
      }
    }
  }
`;
