import React from 'react';
import { Box } from '../common/Box';
import { Sections_SanityDiamondSection } from '../../__generated__/graphql';
import { InnerGrid } from '../common/InnerGrid';
import { MiniBlockContentRenderer } from '../common/MiniBlockContentRenderer';
import { useLocale } from '../CurrentLocale';
import { LineHeading } from '../common/LineHeading';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { ReactComponent as Diamond } from '../../images/esatto-diamond.svg';
import { useInViewSpring } from '../../hooks/useInViewSpring';
import { springAnimationWithDelay2, AnimatedBox, springAnimation } from './grid/gridHelpers';
import styled from 'styled-components';
import { useIsMobile } from '../../hooks/useIsMobile';
import { bodyPadding } from '../../theme/theme';

export type DiamondSectionProps = {
  section: { raw?: any | null | undefined } & Sections_SanityDiamondSection;
};

export const DiamondSection: React.FC<DiamondSectionProps> = ({ section }) => {
  const locale = useLocale();
  const isMobile = useIsMobile();

  const animation = isMobile ? springAnimation : springAnimationWithDelay2;

  const [ref1, sProps1] = useInViewSpring({
    spring: animation,
    index: 0,
  });

  const [ref2, sProps2] = useInViewSpring({
    spring: animation,
    index: 1,
  });

  const { raw, sectionTitle } = section;

  return (
    <Box as="section" gridColumn="2/-2" padding={bodyPadding}>
      <LineHeading>{extractWithLocale(sectionTitle, locale)}</LineHeading>
      <InnerGrid columns={[1, 2]}>
        <div ref={ref1}>
          <AnimatedBox p={[4, 4, 4, 6]} style={sProps1}>
            <StyledDiamond />
          </AnimatedBox>
        </div>
        <div ref={ref2}>
          <AnimatedBox pt={[0, 4, 4, 6]} style={sProps2}>
            <MiniBlockContentRenderer body={raw.text[locale]} />
          </AnimatedBox>
        </div>
      </InnerGrid>
    </Box>
  );
};

const StyledDiamond = styled(Diamond)`
  fill: ${props => props.theme.colors.text};
  stroke: ${props => props.theme.colors.text};
`;
