import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { useCategoryMenu } from '../../hooks/useCategoryMenu';
import { useTranslation } from '../../i18n/useTranslation';
import { bodyPadding } from '../../theme/theme';
import { addURLPrefix } from '../../utils/addURLPrefix';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { getLocalizedUri } from '../../utils/getLocalizedLink';
import {
  ArticleListQuery,
  ArticleListQuery_articles_nodes,
  ArticleListQuery_articles_nodes_gridImage,
  ArticleListQuery_articles_nodes_teaser_gridTeaserImage,
  Sections_SanityArticleListSection,
} from '../../__generated__/graphql';
import { ArticleTeaser } from '../ArticleTeaser';
import { CategoriesMenu } from '../CategoriesMenu';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { LinkWithArrrow } from '../common/LinkWithArrow';
import { useLocale } from '../CurrentLocale';
import { StuffTeaser } from '../StuffTeaser';
import { springAnimationWithDelay3 } from './grid/gridHelpers';

export type ArticleListSectionProps = {
  section: Sections_SanityArticleListSection;
};

interface ArticleListItem extends ArticleListQuery_articles_nodes {
  image: ArticleListQuery_articles_nodes_gridImage | null;
  teaserImage: ArticleListQuery_articles_nodes_teaser_gridTeaserImage | null | undefined;
}

export const ArticleListSection: React.FC<ArticleListSectionProps> = ({ section }) => {
  const [selected, setSelected] = useCategoryMenu('stuff');
  const data = useStaticQuery<ArticleListQuery>(query);
  const locale = useLocale();
  const t = useTranslation();

  const articles = data.articles.nodes;
  const categories = data.categories.nodes;

  const isGrid = section.layout === 'grid';

  const featuredArticle = articles.find(({ isSticky }) => isSticky);

  let filtered = articles
    .reduce((acc, article) => {
      if (selected && selected !== article.category?.title?.toLowerCase()) {
        return acc;
      }

      const mapped = {
        ...article,
        image: isGrid ? article.gridImage : article.floatingImage,
        teaserImage: isGrid ? article.teaser?.gridTeaserImage : article.teaser?.floatingTeaserImage,
      };

      if (isGrid && featuredArticle?.id === article.id) {
        return [
          {
            ...article,
            image: article.featuredImage,
            teaserImage: article.teaser?.featuredTeaserImage,
          },
          ...acc,
        ];
      }

      return [...acc, mapped];
    }, [] as ArticleListItem[])
    .slice(0, section.visibleCount || undefined);

  const showButton = articles.length > filtered.length;

  const handleSeeAllArticles = () => {
    if (selected) {
      setSelected(null);
      return;
    }
    const allArticlesLink = getLocalizedUri('/stuff', locale);
    navigate(allArticlesLink);
  };

  const renderArticle = (article: ArticleListItem, i: number) => {
    const image =
      article?.teaserImage?.asset?.gatsbyImageData ??
      article?.image?.asset?.gatsbyImageData ??
      null;
    const videoLength = article?.embeddedVideo?.videoLength ?? null;

    const eventDates =
      article.events && article.events[0]?.eventDates
        ?.filter(date => date && date?.startDate)
        .map(date => new Date(date?.startDate).valueOf()) || [];
    const eventDate = eventDates.length && new Date(Math.min.apply(null, eventDates) || '');

    const teaserHeading = extractWithLocale(article.teaser?.teaserHeading, locale);
    const teaserPreamble = extractWithLocale(article.teaser?.teaserPreamble, locale);
    const articleHeading = extractWithLocale(article.heading, locale);
    let imageAltText = extractWithLocale(article.teaser?.imageAltText, locale) ?? '';

    if (imageAltText === '') {
      imageAltText = extractWithLocale(article.imageAltText, locale) ?? '';
    }

    const slug = extractWithLocale(article.slug, locale)?.current ?? null;

    const url = addURLPrefix(slug, 'article', locale);

    const TeaserComponent = isGrid ? StuffTeaser : ArticleTeaser;

    if (!slug) return null;

    const isFeatured = article.isSticky && i === 0;
    const teaserMarginBottom = isFeatured ? [3, 4] : [3, 5];

    return (
      <TeaserComponent
        index={i}
        isFeatured={isFeatured}
        key={article.id}
        title={teaserHeading || articleHeading || ''}
        preamble={teaserPreamble}
        link={url}
        kicker={article.category?.title || 'Stuff'}
        image={image}
        eventDate={eventDate || null}
        imageAltText={imageAltText}
        fluidImage={image}
        bg="inverseBg"
        desktopSpringAnimation={springAnimationWithDelay3}
        mb={teaserMarginBottom}
        trackLength={videoLength}
      />
    );
  };

  return (
    <InnerGrid columns={isGrid ? 3 : 4} as="section" px={bodyPadding} mb={6}>
      {isGrid && (
        <CategoriesMenu categories={categories} selected={selected} onChange={setSelected} />
      )}
      {filtered.map((article, index) => renderArticle(article, index))}
      {showButton && (
        <Box gridColumn="1 / -1">
          <LinkWithArrrow onClick={handleSeeAllArticles} style={{ textDecoration: 'underline' }}>
            {t('goToArticles')}
          </LinkWithArrrow>
        </Box>
      )}
    </InnerGrid>
  );
};

const query = graphql`
  query ArticleListQuery {
    articles: allSanityArticle(
      sort: { fields: publishDate, order: DESC }
      filter: { isVisibleOnList: { ne: false } }
    ) {
      nodes {
        ...ArticleTeaser
      }
    }

    categories: allSanityCategory {
      nodes {
        title
      }
    }
  }

  fragment ArticleTeaser on SanityArticle {
    __typename
    id
    heading {
      en
      sv
    }
    category {
      title
    }
    isSticky
    isVisibleOnList
    publishDate

    slug {
      en {
        current
      }
      sv {
        current
      }
    }
    gridImage: image {
      asset {
        gatsbyImageData(width: 600, height: 375)
      }
    }
    featuredImage: image {
      asset {
        gatsbyImageData(width: 800, height: 480)
      }
    }
    floatingImage: image {
      asset {
        gatsbyImageData(width: 1280, height: 800)
      }
    }
    imageAltText {
      sv
      en
    }
    teaser {
      teaserHeading {
        sv
        en
      }
      teaserPreamble {
        sv
        en
      }
      imageAltText {
        sv
        en
      }
      gridTeaserImage: teaserImage {
        asset {
          gatsbyImageData(width: 600, height: 375)
        }
      }
      featuredTeaserImage: teaserImage {
        asset {
          gatsbyImageData(width: 800, height: 480)
        }
      }
      floatingTeaserImage: teaserImage {
        asset {
          gatsbyImageData(width: 1280, height: 800)
        }
      }
    }
    events {
      eventDates {
        startDate
      }
    }
    embeddedVideo {
      videoLength
    }
  }
`;
