import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { bodyPadding } from '../../theme/theme';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { getLinkTitle, getLocalizedLink } from '../../utils/getLocalizedLink';
import { Sections_SanityLinkGroupSection } from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { LineHeading } from '../common/LineHeading';
import { LinkWithArrrow } from '../common/LinkWithArrow';
import { useLocale } from '../CurrentLocale';
import { useTrail } from 'react-spring';
import { AnimatedBox, springAnimationWithDelay2, springAnimation } from './grid/gridHelpers';
import { useInView } from 'react-intersection-observer';
import { Text } from '../common/Text';
import { useInViewSpring } from '../../hooks/useInViewSpring';
import { useIsMobile } from '../../hooks/useIsMobile';

interface LinkGroupSectionProps {
  section: Sections_SanityLinkGroupSection;
}

export const LinkGroupSection: React.FC<LinkGroupSectionProps> = ({ section }) => {
  const locale = useLocale();
  const { colors } = useContext(ThemeContext);
  const isMobile = useIsMobile();

  const [ref, springProps] = useInViewSpring({
    spring: isMobile ? springAnimation : springAnimationWithDelay2,
  });

  const maxGroups = 4;

  const { linkGroups } = section;

  if (!linkGroups) return null;
  return (
    <Box as="section" gridColumn="2 / -2" px={bodyPadding} pb={6}>
      <Box gridColumn="1 / -1" mb={['-1.5em', '-2.5em']}>
        <LineHeading fontWeight={500} />
      </Box>
      <div ref={ref}>
        <AnimatedBox style={springProps}>
          <InnerGrid columns={[1, 2, 2, maxGroups]}>
            {linkGroups.map(linkGroup => {
              const links = linkGroup?.links;
              if (!links) return null;

              const [ref, inView] = useInView({ triggerOnce: true });

              const trail = useTrail(links.length, {
                opacity: inView ? 1 : 0,
                translate: inView ? 'translateY(0px)' : 'translateY(20px)',
              });

              const heading = extractWithLocale(linkGroup?.heading ?? null, locale);

              return (
                <Box key={linkGroup?._key || ''} height="fit-content">
                  {heading && (
                    <Text fontSize={[3, 4, 5]} mt="5px" mb="40px" fontWeight={900} fontFamily="Quarto A, Quarto, Quarto B">
                      {heading}
                    </Text>
                  )}
                  <LinkGroupWrapper mb={[5, 0]} mr={3}>
                    {trail.map((props, index) => {
                      const item = links[index];

                      if (!item) return null;
                      return (
                        <Box key={item._key ?? index} borderTop={`1px solid ${colors.text4}`}>
                          <div ref={ref}>
                            <AnimatedBox style={props}>
                              {/* Replace the Box to get links. */}
                              {/* <LinkWithArrrow
                                href={getLocalizedLink(item, locale)}
                                target={
                                  item?.__typename === 'SanityOutboundLink' ? '_blank' : undefined
                                }
                                fontSize={[2, 3]}
                              > */}
                              <Box
                                overflowX="hidden"
                                py="1em"
                                position="relative"
                                display="flex"
                                alignItems="center"
                                pr="3em"
                              >
                                <Text fontSize={[2, 3]} fontWeight={300}>
                                  {getLinkTitle(item, locale)}
                                </Text>
                              </Box>
                              {/* </LinkWithArrrow> */}
                            </AnimatedBox>
                          </div>
                        </Box>
                      );
                    })}
                  </LinkGroupWrapper>
                </Box>
              );
            })}
          </InnerGrid>
        </AnimatedBox>
      </div>
    </Box>
  );
};

const LinkGroupWrapper = styled(Box)`
  border-bottom: 1px solid ${props => props.theme.colors.text4};
`;
