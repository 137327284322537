import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Sections_SanityEmbeddedVideoSection,
  Sections_SanityYoutubeSection,
} from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { VimeoSection } from './VimeoSection';
import { YouTubeSection } from './YouTubeSection';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bodyPadding } from '../../theme/theme';
import TrackLength from '../TrackLength';
import { useCurrentThemeName } from '../ThemeChanger';
import PlayTeaserOverlay from '../PlayTeaserOverlay';

export type EmbeddedVideoSectionProps = {
  section: Sections_SanityEmbeddedVideoSection;
  space?: boolean;
  darkVersion?: boolean;
  videoLength?: string | null;
};

export const EmbeddedVideoSection: React.FC<EmbeddedVideoSectionProps> = ({
  section,
  space,
  darkVersion,
  videoLength,
}) => {
  const hasPreviewImage = !!section.imagePreview;
  const [showVideo, setShowVideo] = useState(!hasPreviewImage);

  const videoType = getVideoType(section.videoUrl);
  const Video = videoType === 'vimeo' ? VimeoSection : YouTubeSection;

  const videoSection =
    videoType === 'vimeo'
      ? section
      : ({
          ...section,
          youtubeUrl: section.videoUrl,
        } as unknown as Sections_SanityYoutubeSection);

  const runVideo = () => {
    setShowVideo(true);
  };

  const padding = space ? bodyPadding : [0];
  const margin = space ? [4] : [0];

  const currentThemeName = useCurrentThemeName();

  return (
    <Box as="section" gridColumn="2 / -2" px={padding} mb={margin} style={{ position: 'relative' }}>
      {!showVideo && (
        <PreviewBox onClick={runVideo} darkVersion={darkVersion || false}>
          <GatsbyImage image={section.imagePreview?.asset?.gatsbyImageData} alt="video teaser" />
          <PlayTeaserOverlay type={'video'} />
        </PreviewBox>
      )}
      {showVideo && <Video section={videoSection} autoplay={hasPreviewImage} />}
      {!showVideo && videoLength && (
        <TrackLength length={videoLength} darkVersion={currentThemeName === 'dark'} />
      )}
    </Box>
  );
};

const getVideoType = (url: string | null) => {
  if (!url) return 'error';

  if (url.includes('vimeo')) return 'vimeo';

  return 'youtube';
};

const PreviewBox = styled(Box)<{ darkVersion: boolean }>`
  cursor: pointer;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
`;
