import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { color } from '../../theme/color';
import { bodyPadding, grid } from '../../theme/theme';
import { extractWithLocale } from '../../utils/extractWithLocale';
import {
  AllEmployeesListQuery,
  Employee,
  Sections_SanityAllEmployeesListSection,
} from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { Grid } from '../common/Grid';
import { H1 } from '../common/Heading';
import { InnerGrid } from '../common/InnerGrid';
import { LineHeading } from '../common/LineHeading';
import { Text } from '../common/Text';
import { useLocale } from '../CurrentLocale';
import Obfuscate from 'react-obfuscate';

export type AllEmployeesListSectionProps = {
  section: Sections_SanityAllEmployeesListSection;
};

export const AllEmployeesListSection: React.FC<AllEmployeesListSectionProps> = ({ section }) => {
  const data = useStaticQuery<AllEmployeesListQuery>(query);
  const employees = data.employees?.nodes || [];
  const locale = useLocale();

  const employeesByLetter = employeesByLastName(employees);

  const heading = extractWithLocale(section.heading, locale);

  const { colors } = useContext(ThemeContext);

  return (
    <Box as="section" mt={4} pb={4} gridColumn="1 / -1" bg="inverseBg">
      <Grid gridTemplateColumns={grid}>
        <InnerGrid px={bodyPadding}>
          <Box gridColumn="1 / -1" mt={5}>
            <LineHeading>{heading}</LineHeading>
          </Box>
          <Box gridColumn="1 / -1" py={3}>
            {employeesByLetter.map(({ letter, employees }) => {
              return (
                <InnerGrid
                  key={letter}
                  py={[0, 4]}
                  borderTop={['none', `solid 1px ${colors.text}`]}
                >
                  <Box display={['none', 'block']} gridColumn={['1 / -1', '1 / 2']}>
                    <H1 as="div" m={0}>
                      {letter}
                    </H1>
                  </Box>
                  <Box gridColumn={['1 / -1', '2 / -1']}>
                    <InnerGrid columns={[1, 2, 3]} gridGap={3}>
                      {employees.map((employee, index) => {
                        return (
                          <Box key={employee.id} mb={[3, 4]}>
                            {employee.contact && (
                              <>
                                <Box mb={2}>
                                  {employee.contact.image?.asset?.gatsbyImageData ? (
                                    <GatsbyImage
                                      image={employee.contact.image.asset.gatsbyImageData as any}
                                      alt="employee image"
                                    />
                                  ) : (
                                    <PlaceholderImage index={index} />
                                  )}
                                </Box>
                                <Text fontWeight={500} color="text">
                                  {employee.contact.firstName} {employee.contact.lastName}
                                </Text>
                                <Obfuscate
                                  style={{
                                    color: colors.text,
                                    fontWeight: 300,
                                    textDecoration: 'none',
                                  }}
                                  email={employee.contact.email}
                                />
                                {employee.office?.contact && (
                                  <Text color="text" fontWeight={300}>
                                    {employee.office.contact.firstName}
                                  </Text>
                                )}
                              </>
                            )}
                          </Box>
                        );
                      })}
                    </InnerGrid>
                  </Box>
                </InnerGrid>
              );
            })}
          </Box>
        </InnerGrid>
      </Grid>
    </Box>
  );
};

type EmployeeByLetter = {
  letter: string;
  employees: Employee[];
};

const employeesByLastName = (employees: Employee[]): EmployeeByLetter[] => {
  const byLetter: { [key: string]: Employee[] } = {};

  for (let employee of employees) {
    if (!employee.contact) continue;

    const firstChar = (employee.contact.firstName || '')[0];

    if (!byLetter[firstChar]) {
      byLetter[firstChar] = [];
    }

    byLetter[firstChar].push(employee);
  }

  return Object.entries(byLetter)
    .map(([key, value]) => ({
      letter: key,
      employees: value,
    }))
    .sort((a, b) => a.letter.localeCompare(b.letter, ['sv', 'pl']));
};

// TODO: Used when we've created an employee page (see employee.tsx)
// const createSlug = (employee: Employee) => {
//   return slugify(`${employee?.contact?.firstName} ${employee?.contact?.lastName}`.trim());
// };

const placeholderColors = [
  color.orange,
  color.beige,
  color.blue,
  color.red,
  color.pink,
  color.green,
];

const PlaceholderImage = styled.div<{ index: number }>`
  width: 100%;
  padding-bottom: 56.2666667%;
  background-color: ${props => placeholderColors[props.index % placeholderColors.length]};
  opacity: 0.3;
`;

const query = graphql`
  query AllEmployeesListQuery {
    employees: allSanityEmployee {
      nodes {
        ...Employee
      }
    }
  }

  fragment Employee on SanityEmployee {
    id
    contact {
      email
      firstName
      lastName
      phone
      image {
        asset {
          gatsbyImageData
        }
      }
    }
    office {
      _id
      contact {
        firstName
      }
    }
  }
`;
