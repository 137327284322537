import React, { useMemo } from 'react';
import { addURLPrefix } from '../utils/addURLPrefix';
import { extractWithLocale } from '../utils/extractWithLocale';
import { PageContext } from '../utils/PageProps';
import { CaseListQuery_cases_nodes } from '../__generated__/graphql';
import { springAnimationWithDelay3 } from './sections/grid/gridHelpers';
import { FloatingTeaser, Teaser } from './Teaser';

export type CaseTeaserProps = {
  caseStudy: CaseListQuery_cases_nodes;
  index: number;
  locale: PageContext['locale'];
  gridColumn?: string | string[];
  gridRow?: string | string[];
  mb?: number | number[];
};

export const CaseFloatingTeaser: React.FC<CaseTeaserProps> = ({ caseStudy, locale, index }) => {
  const teaserHeading = extractWithLocale(caseStudy.teaser?.teaserHeading, locale);
  const caseHeading = extractWithLocale(caseStudy.heading, locale);

  const teaserImage = caseStudy?.teaser?.teaserImage?.asset?.gatsbyImageData;
  const caseImage = caseStudy.image?.asset?.gatsbyImageData;
  let imageAltText = extractWithLocale(caseStudy?.teaser?.imageAltText, locale) ?? '';

  if (imageAltText === '') {
    imageAltText = extractWithLocale(caseStudy.imageAltText, locale) ?? '';
  }

  const slug = addURLPrefix(
    extractWithLocale(caseStudy.slug, locale)?.current ?? '',
    'case',
    locale
  );

  const tags = useMemo(() => {
    return (caseStudy.tags ?? []).map(tag => {
      const slug = extractWithLocale(tag?.slug ?? null, locale);
      return {
        title: tag?.title ?? '',
        link: addURLPrefix(slug?.current, 'tag', locale),
      };
    });
  }, [caseStudy, locale]);

  return (
    <FloatingTeaser
      title={teaserHeading || caseHeading || ''}
      kicker={caseStudy.client?.name ?? ''}
      tagPosition="top"
      tags={tags}
      fluidImage={teaserImage ?? caseImage}
      imageAltText={imageAltText}
      index={index}
      link={slug}
    />
  );
};

export const CaseGridTeaser: React.FC<CaseTeaserProps> = ({ caseStudy, locale, index }) => {
  const heading = extractWithLocale(caseStudy.heading, locale) ?? '';
  const slug = addURLPrefix(
    extractWithLocale(caseStudy.slug, locale)?.current ?? '',
    'case',
    locale
  );

  const tags = useMemo(() => {
    return (caseStudy.tags ?? []).map(tag => {
      const slug = extractWithLocale(tag?.slug ?? null, locale);
      return {
        title: tag?.title ?? '',
        link: addURLPrefix(slug?.current, 'tag', locale),
      };
    });
  }, [caseStudy, locale]);

  const teaserImage = caseStudy?.teaser?.teaserImage?.asset?.gatsbyImageData;
  const caseImage = caseStudy.image?.asset?.gatsbyImageData;
  let imageAltText = extractWithLocale(caseStudy?.teaser?.imageAltText, locale) ?? '';

  if (imageAltText === '') {
    imageAltText = extractWithLocale(caseStudy.imageAltText, locale) ?? '';
  }

  return (
    <Teaser
      title={heading}
      kicker={caseStudy.client?.name ?? ''}
      tagPosition="top"
      type="grid"
      tags={tags}
      fluidImage={teaserImage ?? caseImage}
      imageAltText={imageAltText}
      index={index}
      link={slug}
      desktopSpringAnimation={springAnimationWithDelay3}
      mb={[4, 5]}
    />
  );
};
