import { PageQuery_page_sections } from '../__generated__/graphql';
import { Section } from '../components/sections/SectionRenderer';

export const combineFullSections = (
  sections: PageQuery_page_sections[],
  rawSections: any[] | null
) =>
  sections.map(section => {
    const raw = rawSections?.find((rawSection: any) => rawSection._key === section._key);
    const newSection: Section = { ...section, raw };
    return newSection;
  });
