import { ResizeObserver as polyfill } from '@juggle/resize-observer';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback } from 'react';
import { animated, config, useSpring } from 'react-spring';
import useMeasure, { RectReadOnly } from 'react-use-measure';
import styled from 'styled-components';
import { useInViewSpring, UseInViewSpringProps } from '../hooks/useInViewSpring';
import { useIsMobile } from '../hooks/useIsMobile';
import { color } from '../theme/color';
import { themeTransition } from '../theme/themeTransition';
import { getFixedGifSrc } from '../utils/getFixedGifSrc';
import { AspectBox } from './common/AspectBox';
import { Box } from './common/Box';
import { Text } from './common/Text';
import { AnimatedBox, springAnimation } from './sections/grid/gridHelpers';
import { ReactComponent as ArrowIcon } from '../images/icons/link-arrow-orange.svg';

const calc = (x: number, y: number, bounds: RectReadOnly) => {
  const middleX = bounds.left + bounds.width / 2;
  const middleY = bounds.top + bounds.height / 2;
  return [-(y - middleY) / 50, (x - middleX) / 50, 1.03];
};
const trans = (x: number, y: number, s: number) => {
  return `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
};

const imageScale = (x: number, y: number, s: number) => {
  const scale = s < 1 ? 1 : s;
  return `translate(${y}px, ${x}px) scale(${scale})`;
};

export interface TeaserTag {
  title: string;
  link: string;
}

export type TeaserProps = {
  title: string;
  kicker?: string;
  fluidImage?: any;
  link: string;
  index: number;
  imageAltText: string;
  type?: 'grid' | 'floating';
  tags?: TeaserTag[];
  tagPosition?: 'top' | 'bottom';
  gridColumn?: string | string[];
  gridRow?: string | string[];
  mb?: number | number[];
  mt?: any;
  desktopSpringAnimation?: UseInViewSpringProps['spring'];
};

// TODO: Add zoom on hover
// TODO: Add background color on hover with text in a layer behind
export const Teaser: React.FC<TeaserProps> = ({
  title,
  kicker,
  fluidImage,
  imageAltText,
  link,
  type,
  index,
  desktopSpringAnimation = springAnimation,
  gridColumn = ['span 4', 'span 4', 'auto', 'auto'],
  gridRow = 'auto',
  mb = [4, 0],
  mt = [0],
}) => {
  const isMobile = useIsMobile();
  const [ref, springProps] = useInViewSpring({
    spring: isMobile ? springAnimation : desktopSpringAnimation,
    index,
  });
  const [measureRef, bounds] = useMeasure({ scroll: true, debounce: 100, polyfill });

  const [imageStyle, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: config.stiff,
  }));

  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) => set({ xys: calc(x, y, bounds) }),
    [set, bounds]
  );
  const onMouseLeave = useCallback(() => set({ xys: [0, 0, 1] }), [set]);

  fluidImage = getFixedGifSrc(fluidImage);

  return (
    <AnimatedBox
      style={springProps}
      gridColumn={gridColumn}
      gridRow={gridRow}
      pr={[0, 4]}
      zIndex={1}
      mb={mb}
      mt={mt}
    // onMouseMove={onMouseMove}
    // onMouseLeave={onMouseLeave}
    >
      <div ref={ref}>
        <CaseLink to={link}>
          <AnimatedBox mb={3} style={{ overflow: 'hidden' }} ref={measureRef}>
            {fluidImage ? (
              <AnimatedImg
                image={fluidImage}
                style={{ transform: imageStyle.xys.interpolate(imageScale as any) }}
                alt={imageAltText}
              />
            ) : (
              <AspectBox aspectRatio={360 / 600} />
            )}
          </AnimatedBox>
          {kicker && (
            <Text
              fontSize={type === 'grid' ? [1, 2] : [2, 3]}
              fontWeight={[300]}
              mt={type === 'grid' ? [2] : [2, 4]}
            >
              {kicker}
            </Text>
          )}
          <Box mt={0} ml={-1} maxWidth="95%">
            <CaseTitle
              display="inline"
              fontSize={type === 'grid' ? [2, 3, 2, 3, 4] : [3, 4, 5]}
              fontWeight={500}
              lineHeight={1.4}
              p={1}
              mb={[2, 0]}
              letterSpacing="-0.02em"
              style={{
                textDecoration: 'none',
              }}
            >
              {title}
            </CaseTitle>
          </Box>
          <Box mt={[3, 4]} maxWidth="95%">
            <ArrowAnchor />
          </Box>
        </CaseLink>
      </div>
    </AnimatedBox>
  );
};

// TODO: animera bild på hover
// click ska ske på hela blocket
export const FloatingTeaser: React.FC<TeaserProps> = props => {
  const i = props.index;
  let marginTop: any = 0;

  if (i > 0) {
    marginTop = [0, 0, 0, 0, '-80px'];
  }

  return (
    <Teaser
      {...props}
      mb={[4, 0]}
      mt={marginTop}
      type="floating"
      gridColumn={['1 / -1', `${i % 2 === 0 ? 1 : 3} / span 2`]}
      gridRow={['auto', `${i + 1} / span 1`]}
    />
  );
};

const ArrowAnchor = styled(ArrowIcon)`
  width: 30px;
  stroke: #000;
  fill: none;
`;

const CaseTitle = styled(Text)`
  ${themeTransition('background-color', 0.3)}
  position: relative;
`;

const CaseLink = styled(Link)`
  text-decoration: none;

  &:hover ${CaseTitle} {
    background-color: ${color.orange};
  }
`;

const AnimatedImg = animated(GatsbyImage);
